import pageimage from "./image/3d2.png";
import pageimage2 from "./image/3d3.png";
import sale from "./image/sale.png";
import pick from "./image/pick.png";
import guide from "./image/guide.png";
import pickMe from "./image/pickMe.png";
import imgOne from "./image/11.png";
import imgTwo from "./image/22.png";
import imgThree from "./image/33.png";
import imgFour from "./image/44.png";

import { FaFileDownload } from "react-icons/fa";

import {
  AppFlexBox,
  BorderNoneColorBox,
  ButtonGet,
  ColorBlackWeight800,
  CursorPointerWidthBox,
  FileInput,
  FlexBox,
  FlexBoxBottomBox,
  FlexBoxMarginTop05Bottom3,
  FlexFont18Box,
  FlexMargiTop2remBox,
  FlexOne,
  FlexPadding3Box,
  FlexSBBox,
  FontGraySize12Box,
  FontGraySize15Box,
  FontSize18pxBox,
  FontSizeBox,
  FontSizeBox2,
  FontSizeBox3,
  Fontsize30Weight800Margin10,
  GuideBoxImg,
  GuideImageBox,
  GuideImageBox2,
  GuideTextBox,
  GuideTextFinal,
  GuideTextFinalBox,
  ImageMineBox,
  ImageMineBox2,
  MineImageBox,
  MustBr,
  MustText,
  MustTextBox,
  MustTwoBox,
  OptionBackgroundBox,
  OptionBackgroundImage,
  OptionBackgroundImageBox,
  OptionBackgroundLabelStyle,
  OptionBox,
  OptionBoxStyle,
  OptionClothesStyleBox,
  OptionFileImageBox,
  OptionFileImageLabel,
  OptionFileImageViewBox,
  OptionFileMapBox,
  OptionFileViewImage,
  OptionImageDelButton,
  OptionInputTextBox,
  OptionTextBoxStyle,
  OptionWidthBox,
  PrImgBox,
  ProductImageBox,
  ProductLeftFlexBox,
  ProductRightFlexBox,
  ProductTextBox,
  ProductTopFlexBox,
  SizeBoxHeight05Rem,
  SizeBoxHeight1Rem,
  SizeBoxHeight2Rem,
  SizeBoxWidth02Rem,
  SizeBoxWidth3rem,
  SpanColorRedBox,
  SpanTextBox,
  SpanTextBox2,
  StyledSelect,
  TextColorGrayBox,
  ThreeButtonBox,
  ThreeButtonSpaceBox,
  ThreeTwoButtonBox,
  ProductRightFlexBox2,
  ProductLeftFlexBox2,
  GuidText2,
  GuidText,
} from "./style/AppStyle";
import React, { useEffect, useState } from "react";
import testImage1 from "./image/11.png";
import testImage2 from "./image/22.png";
import testImage3 from "./image/33.png";
import testImage4 from "./image/44.png";
import testImage5 from "./image/www.jpg";
import testImage6 from "./image/qqq.jpg";
import { useNavigate } from "react-router-dom";
import { Header } from "./header/header";
import { Footer } from "./footer/footer";
import { getCookie, removeCookie, setCookie } from "./util/cookies";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import {
  productResetAct,
  resetAllAct,
  setBackImgAct,
  setColorAct,
  setEmailAct,
  setOneImgAct,
  setStyleOneAct,
  setStyleThreeAct,
  setStyleTwoAct,
  setThreeImgAct,
  setTwoImgAct,
} from "./redux/action/productAction";
import { emailRegex } from "./util/const";
import { removeToken } from "./redux/action/LoginAction";

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isMobile, setMobile] = useState(window.innerWidth <= 450);
  useEffect(() => {
    if (isMobile) {
      console.log('모바일 디바이스입니다.');
    } else {
      console.log('웹 디바이스입니다.');
    }
  }, [isMobile]);


  useEffect(() => {
    // 화면 크기 변경 핸들러
    const handleResize = () => {
      const width = window.innerWidth;
      setWindowWidth(width);
      setMobile(width <= 480); // 450px 이하일 때 isMobile을 true로 설정
    };

    // resize 이벤트 리스너 추가
    window.addEventListener('resize', handleResize);

    // 초기 상태 설정
    handleResize();

    // 컴포넌트 언마운트 시 리스너 제거
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // 화면 크기 변경을 실시간으로 추적
  // useEffect(() => {
  //   const handleResize = () => {
  //     setWindowWidth(window.innerWidth);
  //   };

  //   // resize 이벤트 리스너 추가
  //   window.addEventListener("resize", handleResize);

  //   // 컴포넌트 언마운트 시 리스너 제거
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  const [currentImage, setCurrentImage] = useState(pageimage); // 기본 이미지로 pageimage 설정
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedColor, setSelectedColor] = useState("Blue"); // 초기값을 red로 설정
  const [isBackgroundImage, setIsBackgroundImage] = useState(false); // 배경 이미지 추가 여부
  const [image, setImage] = useState(null); // 선택된 이미지 저장
  // const [isTokenCheck, setTokenCheck] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // 버튼 비활성화 상태 관리

  const [isEmail, setEmail] = useState("");

  const [isToken, setToken] = useState(null);

  // useEffect(() => {
  //   const token = getCookie("accessToken");

  //   if (!token) {
  //     setTokenCheck(false);
  //   } else {
  //     setTokenCheck(true);
  //   }
  // });

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handlePayPage = (email, style, color, backImg, img, isMobile) => {
    const token = getCookie("accessToken"); // 토큰을 다시 확인
    /// 이메일을 입력하지 않았거나 형식에 맞지 않을때
    if (!email || email.trim() === "" || !emailRegex.test(email)) {
      Swal.fire({
        title: `유효하지 않은 이메일입니다.\n이메일을 확인해 주세요.`,
        icon: `error`,
      });
      return;
    }

    /// 사진첨부가 안되었을경우 실행안되게
    if (selectedImages.length === 0) {
      // 사진이 첨부되지 않았을 경우 처리할 로직
      Swal.fire({
        title: `한 장 이상의 사진을 첨부해주세요`,
        icon: `error`,
      });
      return;
    }

    dispatch(productResetAct());


    if (isMobile === true) {
      if (token) {
        dispatch(setEmailAct(email)); // 이메일을 리덕스로 디스패치
        dispatch(setStyleOneAct(style[0]));
        dispatch(setStyleTwoAct(style[1]));
        dispatch(setStyleThreeAct(style[2]));
        dispatch(setColorAct(color));
        dispatch(setBackImgAct(backImg));
        dispatch(setOneImgAct(img[0]));
        dispatch(setTwoImgAct(img[1]));
        dispatch(setThreeImgAct(img[2]));

        navigate("/paymentM");
      } else {
        Swal.fire({
          title: `로그인이 필요합니다.`,
          icon: `error`,
        }).then((result) => {
          if (result.isConfirmed) {
            dispatch(removeToken());
            dispatch(resetAllAct());
            navigate("/LoginPage");
          }
        });
      }
    }

    /// 기존로직
    if (isMobile === false) {
      if (token) {
        dispatch(setEmailAct(email)); // 이메일을 리덕스로 디스패치
        dispatch(setStyleOneAct(style[0]));
        dispatch(setStyleTwoAct(style[1]));
        dispatch(setStyleThreeAct(style[2]));
        dispatch(setColorAct(color));
        dispatch(setBackImgAct(backImg));
        dispatch(setOneImgAct(img[0]));
        dispatch(setTwoImgAct(img[1]));
        dispatch(setThreeImgAct(img[2]));
  
        // 팝업 창을 열고 그 참조를 변수에 저장
        const paymentUrl = `${window.location.origin}/payment`;
        const popup = window.open(paymentUrl, "_blank", "width=530,height=600");
  
        // 버튼을 비활성화
        setIsButtonDisabled(true);
  
        // 팝업 창이 닫혔는지 확인
        const checkPopupClosed = setInterval(() => {
          if (popup.closed) {
            clearInterval(checkPopupClosed);
            setIsButtonDisabled(false); // 팝업이 닫히면 버튼 활성화
          }
        }, 1000);
      } else {
        Swal.fire({
          title: `로그인이 필요합니다.`,
          icon: `error`,
        }).then((result) => {
          if (result.isConfirmed) {
            dispatch(removeToken());
            dispatch(resetAllAct());
            navigate("/LoginPage");
          }
        });
      }
    }
  };

  const handleOpenPaymentPage = () => {
    const paymentUrl = `${window.location.origin}/payment`;
    window.open(paymentUrl, "_blank", "width=530,height=600");
  };

  // 색상이 변경되면 상태 업데이트
  const handleChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedColor(selectedValue);

    if (selectedValue === "back") {
      setIsBackgroundImage(true); // 배경이미지 추가 선택
    } else {
      setIsBackgroundImage(false); // 색상 선택
    }
  };

  const handleBackImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result); 
      };
      reader.readAsDataURL(file); 
    }
  };
  const handleSelect = (item) => {
    if (selectedItems.includes(item)) {
      // 선택 취소
      setSelectedItems(selectedItems.filter((selected) => selected !== item));
    } else if (selectedItems.length < 3) {
      // 선택 추가
      setSelectedItems([...selectedItems, item]);
    }
  };

  const renderFlexBox = (items) =>
    items.map((item) => (
      <AppFlexBox
        key={item}
        isSelected={selectedItems.includes(item)}
        onClick={() => handleSelect(item)}
      >
        {item}
      </AppFlexBox>
    ));
  const handleImageChange = (e) => {
    const files = e.target.files;
    if (files.length + selectedImages.length <= 3) {
      // 최대 3개의 이미지만 선택하도록 처리
      const newImages = [...selectedImages];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const reader = new FileReader();
        reader.onloadend = () => {
          newImages.push(reader.result);
          setSelectedImages([...newImages]);
        };
        reader.readAsDataURL(file); // 파일을 base64로 읽기
      }
    } else {
      alert("최대 3장까지만 선택 가능합니다.");
    }
  };

  // 이미지를 제거하는 함수
  const removeImage = (index) => {
    const newImages = [...selectedImages];
    newImages.splice(index, 1);
    setSelectedImages(newImages);
  };

  const asd = "djkshdjkshjkds";

  const changeHandleToken = () => {
    setCookie("accessToken", asd, {
      path: "/",
      maxAge: 3600,
    });
  };

  useEffect(() => {
    setToken(getCookie("accessToken"));
  }, [isToken]);

  const changeHandleDelToken = () => {
    // dispatch(removeToken());
    // dispatch(resetAllAct());
    removeCookie("accessToken", { path: "/" });
  };

  return (
    <div className="App">
      <Header />
      <div
        className="container-xxl"
        style={{
          backgroundColor: "#fff",
        }}
      >
        <FlexPadding3Box>
          <TextColorGrayBox>
            {"3D profile caricature illustration"}
          </TextColorGrayBox>
        </FlexPadding3Box>
        {windowWidth >= 850 ? (
          <ProductTopFlexBox>
            <ProductLeftFlexBox>
              <ProductImageBox src={currentImage} alt="Page" />
            </ProductLeftFlexBox>
            <ProductRightFlexBox>
              {/* <button onClick={() => changeHandleToken()}>토큰바꾸기</button>
   <button onClick={() => changeHandleDelToken()}>토큰지우기</button> */}

              <FlexFont18Box>
                <div style={{ marginRight: `5px` }}>
                  {"3D 캐릭터를 그려드립니다"}
                </div>
                <CursorPointerWidthBox>
                  <ProductImageBox src={sale} alt="sale" />
                </CursorPointerWidthBox>
                <SizeBoxWidth02Rem />
                <CursorPointerWidthBox>
                  <ProductImageBox src={pick} alt="pick" />
                </CursorPointerWidthBox>
              </FlexFont18Box>
              <FlexMargiTop2remBox>
                <FontGraySize12Box>
                  <ColorBlackWeight800>3D 프로필</ColorBlackWeight800>
                  <ColorBlackWeight800>캐리커쳐 일러스트</ColorBlackWeight800>
                  {/* <LineThroughBox>38,500</LineThroughBox>원 */}
                </FontGraySize12Box>
              </FlexMargiTop2remBox>
              <FlexBox>
                <SpanColorRedBox>50%</SpanColorRedBox>
                <SpanTextBox>10,000원</SpanTextBox>
                <SpanTextBox2>5,000원</SpanTextBox2>
              </FlexBox>

              {/* <PayBox>
     <div>
       <FontMarginLeftBox>쿠폰 사용시</FontMarginLeftBox>
       <div>
         <Font18Weight800Margin>24,500원</Font18Weight800Margin>
       </div>
     </div>
     <KuponButton>
       쿠폰받기
       <IoMdArrowDown
         size={16}
         style={{ marginLeft: `5px`, marginBottom: `0.2rem` }}
       />
     </KuponButton>
   </PayBox> */}
              <hr></hr>
              {/* <SizeBoxHeight1Rem /> */}
              <ProductTextBox>
                <div>내 인생의 모습, 나의 성장 모습을</div>
                <div>❤️ 실사화가 아닌 귀여운 스타일로 그려드립니다 ❤️</div>
                <div>가족, 친구, 연인, 나에게 작은 선물은 어떠세요?</div>
                {/* <ColorBlackWeight800>3D 사진 그림</ColorBlackWeight800>
     <ColorBlackWeight800>3D photo illustration</ColorBlackWeight800> */}
                {/* <br></br> */}
                {/* <ColorBlackWeight800>🔥 필독 🔥</ColorBlackWeight800>
     <div>{"1:1 주문제작 특성상 작품 전달 후 환불이 어렵습니다."}</div>
     <div>
       {"상업적으로 이용/2차 가공은 사전에 협의없이 불가합니다."}
     </div>

     <br></br>
     <ButtonGaide>노션 앱 기본 이용 가이드</ButtonGaide> */}
                {/* <SizeBoxHeight1Rem /> */}
              </ProductTextBox>
              <hr></hr>
              {/* <SizeBoxHeight15Rem /> */}
              <OptionBox>
                <OptionBoxStyle>
                  <OptionTextBoxStyle>받으실 이메일 주소</OptionTextBoxStyle>
                  <OptionWidthBox>
                    <OptionInputTextBox
                      type="text"
                      value={isEmail}
                      onChange={handleEmail}
                    />
                  </OptionWidthBox>
                  <SizeBoxHeight1Rem />
                  <OptionTextBoxStyle>옷 스타일(3개 이내)</OptionTextBoxStyle>
                  <SizeBoxHeight1Rem />
                  {windowWidth <= 920 ? (
                    <>
                      <OptionClothesStyleBox>
                        {renderFlexBox(["경찰", "기모노"])}
                      </OptionClothesStyleBox>
                      <SizeBoxHeight05Rem />
                      <OptionClothesStyleBox>
                        {renderFlexBox(["스키웨어", "요리사"])}
                      </OptionClothesStyleBox>
                      <SizeBoxHeight05Rem />
                      <OptionClothesStyleBox>
                        {renderFlexBox(["운동복", "웨딩드레스"])}
                      </OptionClothesStyleBox>
                      <SizeBoxHeight05Rem />
                      <OptionClothesStyleBox>
                        {renderFlexBox(["의사", "정장"])}
                      </OptionClothesStyleBox>
                      <SizeBoxHeight05Rem />
                      <OptionClothesStyleBox>
                        {renderFlexBox(["치파오", "캐쥬얼"])}
                      </OptionClothesStyleBox>
                      <SizeBoxHeight05Rem />
                      <OptionClothesStyleBox>
                        {renderFlexBox(["학생", "한복"])}
                      </OptionClothesStyleBox>
                    </>
                  ) : (
                    <>
                      {" "}
                      <OptionClothesStyleBox>
                        {renderFlexBox([
                          "경찰",
                          "기모노",
                          "스키웨어",
                          "요리사",
                          "운동복",
                          "웨딩드레스",
                        ])}
                      </OptionClothesStyleBox>
                      <SizeBoxHeight05Rem />
                      <OptionClothesStyleBox>
                        {renderFlexBox([
                          "의사",
                          "정장",
                          "치파오",
                          "캐쥬얼",
                          "학생",
                          "한복",
                        ])}
                      </OptionClothesStyleBox>
                    </>
                  )}

                  <SizeBoxHeight1Rem />
                  <OptionTextBoxStyle>배경선택</OptionTextBoxStyle>
                  <OptionBackgroundBox>
                    <StyledSelect
                      value={selectedColor} // 선택된 색상 반영
                      onChange={handleChange} // 선택이 바뀔 때마다 상태 업데이트
                      selectedColor={selectedColor} // 동적으로 스타일 변경
                      isBackgroundImage={isBackgroundImage} // 배경이미지 여부 전달
                    >
                      <option value="blue">Blue</option>
                      <option value="red">Red</option>
                      <option value="green">Green</option>
                      <option value="yellow">Yellow</option>
                      <option value="orange">Orange</option>
                      <option value="purple">Purple</option>
                      <option value="back">배경이미지 추가</option>
                    </StyledSelect>
                    {isBackgroundImage && (
                      <div>
                        <FileInput
                          type="file"
                          onChange={handleBackImageChange}
                          accept="image/*"
                          multiple
                          id="file-upload2"
                        />
                        <OptionBackgroundLabelStyle htmlFor="file-upload2">
                          <FaFileDownload size={30} />
                        </OptionBackgroundLabelStyle>
                        {image && (
                          <OptionBackgroundImageBox>
                            <OptionBackgroundImage src={image} alt="Selected" />
                          </OptionBackgroundImageBox>
                        )}
                      </div>
                    )}
                  </OptionBackgroundBox>
                  <SizeBoxHeight1Rem />
                  <OptionTextBoxStyle>사진첨부</OptionTextBoxStyle>
                  {/* 파일 선택 버튼 숨기기 */}
                  <FileInput
                    type="file"
                    onChange={handleImageChange}
                    accept="image/*"
                    multiple
                    id="file-upload"
                  />
                  <OptionFileImageBox>
                    <SizeBoxWidth3rem />
                    <OptionFileImageLabel htmlFor="file-upload">
                      <FaFileDownload size={30} />
                    </OptionFileImageLabel>
                    <SizeBoxWidth3rem />
                  </OptionFileImageBox>
                </OptionBoxStyle>
                <OptionFileImageViewBox>
                  {selectedImages.map((image, index) => (
                    <OptionFileMapBox key={index}>
                      <OptionFileViewImage
                        src={image}
                        alt={`selected-${index}`}
                      />
                      <OptionImageDelButton onClick={() => removeImage(index)}>
                        X
                      </OptionImageDelButton>
                    </OptionFileMapBox>
                  ))}
                </OptionFileImageViewBox>
              </OptionBox>
              {/* <SizeBoxHeight15Rem /> */}
              <hr></hr>
              <FlexSBBox>
                <FontGraySize15Box>{"총 상품금액(1개)"}</FontGraySize15Box>
                <FontSize18pxBox>5,000원</FontSize18pxBox>
              </FlexSBBox>
              <SizeBoxHeight2Rem />
              <ThreeButtonBox>
                {/* <SizeBoxWidth2Rem /> */}
                <ButtonGet
                  onClick={() =>
                    handlePayPage(
                      isEmail,
                      selectedItems,
                      selectedColor,
                      image,
                      selectedImages,
                      isMobile
                    )
                  }
                  disabled={isButtonDisabled} // 상태에 따라 버튼 비활성화
                >
                  구매하기
                </ButtonGet>
                {/* <ButtonBlack>장바구니</ButtonBlack>
     <ButtonBlack>
       <IoIosHeartEmpty size={15} /> 18
     </ButtonBlack> */}
                {/* <SizeBoxWidth2Rem /> */}
              </ThreeButtonBox>
            </ProductRightFlexBox>
          </ProductTopFlexBox>
        ) : (
          <>
            <>
              <ProductTopFlexBox>
                <ProductLeftFlexBox2>
                  <ProductImageBox src={currentImage} alt="Page" />
                </ProductLeftFlexBox2>
              </ProductTopFlexBox>

              <div
                style={{
                  display: `flex`,
                  justifyContent: `center`,
                  alignItems: `center`,
                }}
              >
                <ProductRightFlexBox2>
                  <FlexFont18Box>
                    <div style={{ marginRight: `5px` }}>
                      {"3D 캐릭터를 그려드립니다"}
                    </div>
                    <CursorPointerWidthBox>
                      <ProductImageBox src={sale} alt="sale" />
                    </CursorPointerWidthBox>
                    <SizeBoxWidth02Rem />
                    <CursorPointerWidthBox>
                      <ProductImageBox src={pick} alt="pick" />
                    </CursorPointerWidthBox>
                  </FlexFont18Box>
                  <FlexMargiTop2remBox>
                    <FontGraySize12Box>
                      <ColorBlackWeight800>3D 프로필</ColorBlackWeight800>
                      <ColorBlackWeight800>
                        캐리커쳐 일러스트
                      </ColorBlackWeight800>
                      {/* <LineThroughBox>38,500</LineThroughBox>원 */}
                    </FontGraySize12Box>
                  </FlexMargiTop2remBox>
                  <FlexBox>
                    <SpanColorRedBox>50%</SpanColorRedBox>
                    <SpanTextBox>10,000원</SpanTextBox>
                    <SpanTextBox2>5,000원</SpanTextBox2>
                  </FlexBox>

                  {/* <PayBox>
              <div>
                <FontMarginLeftBox>쿠폰 사용시</FontMarginLeftBox>
                <div>
                  <Font18Weight800Margin>24,500원</Font18Weight800Margin>
                </div>
              </div>
              <KuponButton>
                쿠폰받기
                <IoMdArrowDown
                  size={16}
                  style={{ marginLeft: `5px`, marginBottom: `0.2rem` }}
                />
              </KuponButton>
            </PayBox> */}
                  <hr></hr>
                  {/* <SizeBoxHeight1Rem /> */}
                  <ProductTextBox>
                    <div>내 인생의 모습, 나의 성장 모습을</div>
                    <div>❤️ 실사화가 아닌 귀여운 스타일로 그려드립니다 ❤️</div>
                    <div>가족, 친구, 연인, 나에게 작은 선물은 어떠세요?</div>
                    {/* <ColorBlackWeight800>3D 사진 그림</ColorBlackWeight800>
              <ColorBlackWeight800>3D photo illustration</ColorBlackWeight800> */}
                    {/* <br></br> */}
                    {/* <ColorBlackWeight800>🔥 필독 🔥</ColorBlackWeight800>
              <div>{"1:1 주문제작 특성상 작품 전달 후 환불이 어렵습니다."}</div>
              <div>
                {"상업적으로 이용/2차 가공은 사전에 협의없이 불가합니다."}
              </div>

              <br></br>
              <ButtonGaide>노션 앱 기본 이용 가이드</ButtonGaide> */}
                    {/* <SizeBoxHeight1Rem /> */}
                  </ProductTextBox>
                  <hr></hr>
                  {/* <SizeBoxHeight15Rem /> */}
                  <OptionBox>
                    <OptionBoxStyle>
                      <OptionTextBoxStyle>
                        받으실 이메일 주소
                      </OptionTextBoxStyle>
                      <OptionWidthBox>
                        <OptionInputTextBox
                          type="text"
                          value={isEmail}
                          onChange={handleEmail}
                        />
                      </OptionWidthBox>
                      <SizeBoxHeight1Rem />
                      <OptionTextBoxStyle>
                        옷 스타일(3개 이내)
                      </OptionTextBoxStyle>
                      <SizeBoxHeight1Rem />
                      {windowWidth <= 920 ? (
                        <>
                          <OptionClothesStyleBox>
                            {renderFlexBox(["경찰", "기모노"])}
                          </OptionClothesStyleBox>
                          <SizeBoxHeight05Rem />
                          <OptionClothesStyleBox>
                            {renderFlexBox(["스키웨어", "요리사"])}
                          </OptionClothesStyleBox>
                          <SizeBoxHeight05Rem />
                          <OptionClothesStyleBox>
                            {renderFlexBox(["운동복", "웨딩드레스"])}
                          </OptionClothesStyleBox>
                          <SizeBoxHeight05Rem />
                          <OptionClothesStyleBox>
                            {renderFlexBox(["의사", "정장"])}
                          </OptionClothesStyleBox>
                          <SizeBoxHeight05Rem />
                          <OptionClothesStyleBox>
                            {renderFlexBox(["치파오", "캐쥬얼"])}
                          </OptionClothesStyleBox>
                          <SizeBoxHeight05Rem />
                          <OptionClothesStyleBox>
                            {renderFlexBox(["학생", "한복"])}
                          </OptionClothesStyleBox>
                        </>
                      ) : (
                        <>
                          {" "}
                          <OptionClothesStyleBox>
                            {renderFlexBox([
                              "경찰",
                              "기모노",
                              "스키웨어",
                              "요리사",
                              "운동복",
                              "웨딩드레스",
                            ])}
                          </OptionClothesStyleBox>
                          <SizeBoxHeight05Rem />
                          <OptionClothesStyleBox>
                            {renderFlexBox([
                              "의사",
                              "정장",
                              "치파오",
                              "캐쥬얼",
                              "학생",
                              "한복",
                            ])}
                          </OptionClothesStyleBox>
                        </>
                      )}

                      <SizeBoxHeight1Rem />
                      <OptionTextBoxStyle>배경선택</OptionTextBoxStyle>
                      <OptionBackgroundBox>
                        <StyledSelect
                          value={selectedColor} // 선택된 색상 반영
                          onChange={handleChange} // 선택이 바뀔 때마다 상태 업데이트
                          selectedColor={selectedColor} // 동적으로 스타일 변경
                          isBackgroundImage={isBackgroundImage} // 배경이미지 여부 전달
                        >
                          <option value="blue">Blue</option>
                          <option value="red">Red</option>
                          <option value="green">Green</option>
                          <option value="yellow">Yellow</option>
                          <option value="orange">Orange</option>
                          <option value="purple">Purple</option>
                          <option value="back">배경이미지 추가</option>
                        </StyledSelect>
                        {isBackgroundImage && (
                          <div>
                            <FileInput
                              type="file"
                              onChange={handleBackImageChange}
                              accept="image/*"
                              multiple
                              id="file-upload2"
                            />
                            <OptionBackgroundLabelStyle htmlFor="file-upload2">
                              <FaFileDownload size={30} />
                            </OptionBackgroundLabelStyle>
                            {image && (
                              <OptionBackgroundImageBox>
                                <OptionBackgroundImage
                                  src={image}
                                  alt="Selected"
                                />
                              </OptionBackgroundImageBox>
                            )}
                          </div>
                        )}
                      </OptionBackgroundBox>
                      <SizeBoxHeight1Rem />
                      <OptionTextBoxStyle>사진첨부</OptionTextBoxStyle>
                      {/* 파일 선택 버튼 숨기기 */}
                      <FileInput
                        type="file"
                        onChange={handleImageChange}
                        accept="image/*"
                        multiple
                        id="file-upload"
                      />
                      <OptionFileImageBox>
                        <SizeBoxWidth3rem />
                        <OptionFileImageLabel htmlFor="file-upload">
                          <FaFileDownload size={30} />
                        </OptionFileImageLabel>
                        <SizeBoxWidth3rem />
                      </OptionFileImageBox>
                    </OptionBoxStyle>
                    <OptionFileImageViewBox>
                      {selectedImages.map((image, index) => (
                        <OptionFileMapBox key={index}>
                          <OptionFileViewImage
                            src={image}
                            alt={`selected-${index}`}
                          />
                          <OptionImageDelButton
                            onClick={() => removeImage(index)}
                          >
                            X
                          </OptionImageDelButton>
                        </OptionFileMapBox>
                      ))}
                    </OptionFileImageViewBox>
                  </OptionBox>
                  {/* <SizeBoxHeight15Rem /> */}
                  <hr></hr>
                  <FlexSBBox>
                    <FontGraySize15Box>{"총 상품금액(1개)"}</FontGraySize15Box>
                    <FontSize18pxBox>5,000원</FontSize18pxBox>
                  </FlexSBBox>
                  <SizeBoxHeight2Rem />
                  <ThreeButtonBox>
                    {/* <SizeBoxWidth2Rem /> */}
                    <ButtonGet
                      onClick={() =>
                        handlePayPage(
                          isEmail,
                          selectedItems,
                          selectedColor,
                          image,
                          selectedImages,
                          isMobile
                        )
                      }
                      disabled={isButtonDisabled} // 상태에 따라 버튼 비활성화
                    >
                      구매하기
                    </ButtonGet>
                    {/* <ButtonBlack>장바구니</ButtonBlack>
              <ButtonBlack>
                <IoIosHeartEmpty size={15} /> 18
              </ButtonBlack> */}
                    {/* <SizeBoxWidth2Rem /> */}
                  </ThreeButtonBox>
                </ProductRightFlexBox2>
              </div>
            </>
          </>
        )}

        <FlexBoxMarginTop05Bottom3>
          <MineImageBox onMouseEnter={() => setCurrentImage(pageimage)}>
            <ImageMineBox src={pageimage} alt="pageimage" />
          </MineImageBox>
          <ImageMineBox2 onMouseEnter={() => setCurrentImage(pageimage2)}>
            <ImageMineBox src={pageimage2} alt="pageimage2" />
          </ImageMineBox2>
        </FlexBoxMarginTop05Bottom3>
        <ThreeButtonSpaceBox>
          {/* <ThreeOneButtonBox>상세정보</ThreeOneButtonBox> */}
          {/* <ThreeTwoButtonBox>구매평 (9)</ThreeTwoButtonBox> */}
          <ThreeTwoButtonBox>상세정보</ThreeTwoButtonBox>
        </ThreeButtonSpaceBox>
        <div>
          <GuideBoxImg src={guide} alt="가이드" />
          <div>
            <GuideBoxImg src={pickMe} alt="" />
          </div>
          <FontSizeBox>
            ※ 상품 전달시 MOHA 로고는 포함되지 않습니다.
          </FontSizeBox>
          <PrImgBox>
            <GuideBoxImg src={imgOne} alt="사진"></GuideBoxImg>
          </PrImgBox>
          <PrImgBox>
            <GuideBoxImg src={imgTwo} alt="사진"></GuideBoxImg>
          </PrImgBox>
          <PrImgBox>
            <GuideBoxImg src={imgThree} alt="사진"></GuideBoxImg>
          </PrImgBox>
          <PrImgBox>
            <GuideBoxImg src={imgFour} alt="사진"></GuideBoxImg>
          </PrImgBox>
          <MustTextBox>
            <GuidText>꼭 읽어주세요!</GuidText>
            <GuidText2>안내사항</GuidText2>
            <MustBr></MustBr>
          </MustTextBox>
          <MustTwoBox>
            <MustText>
              1. 결과물은 이미지이기 때문에 배송없이 메세지로 보내드려요
            </MustText>
            <MustText>2. 고화질, 선명한 사진이 좋아요 </MustText>
            <MustText>
              3. 두 눈을 뜨고 있고 헤어, 얼굴 전체가 확인 가능한 사진 3장을
              보내주세요{" "}
            </MustText>
            <MustText>
              4. 요청사항이 있으시다면 사진을 보내주실 때 미리 말씀해주세요{" "}
            </MustText>
            <MustText>
              5. 1:1 주문제작 특성상 작품 전달 후 환불이 어려워요{" "}
            </MustText>
            <MustText>
              6. 그림을 상업적으로 사용하고자 하시면 문의 주세요. 임의로 상업적
              사용시 법적 책임을 물을 수 있습니다.{" "}
            </MustText>
            <MustText>
              7. 완성된 작품은 작가의 sns 계정에 업로드 될 수 잇습니다. (원하지
              않으시면 말씀해주세요){" "}
            </MustText>
          </MustTwoBox>
          <FontSizeBox2>선택 가능한 옷 스타일(여자)</FontSizeBox2>
          <FontSizeBox3>
            ※ 상품 전달시 MOHA 로고는 포함되지 않습니다.
          </FontSizeBox3>
          <GuideImageBox2>
            <ProductImageBox src={testImage6} alt="상품이미지" />
          </GuideImageBox2>
          <FontSizeBox2>선택 가능한 옷 스타일(남자)</FontSizeBox2>
          <FontSizeBox3>
            ※ 상품 전달시 MOHA 로고는 포함되지 않습니다.
          </FontSizeBox3>
          <GuideImageBox2>
            <ProductImageBox src={testImage5} alt="상품이미지" />
          </GuideImageBox2>
          {/* <BorderNoneColorBox>
            <FlexBoxBottomBox>
              <Fontsize30Weight800Margin10>
                🔥 작업기간 & 유의사항 🔥
              </Fontsize30Weight800Margin10>
              <div>
                주문 당일에는 2영업일 이내 (주문량에 따라서 기간이 달라질 수
                있는 점 이해바랍니다)
              </div>
              <div>
                캐릭터의 자세/형태는 변경이 불가능합니다. 그림체 특성상 3개
                작품마다 옷은 원하시는 스타일로 맞춰드릴 수 있지만 세부수정은
                불가합니다.
              </div>
              <div>
                완성된 작품은 작가의 sns 계정에 업로드 될 수 있습니다.
                (원치않으시면 말씀해주세요)
              </div>
              <div>작품의 상업적 활용은 불가능합니다</div>
              <div>
                작품은 인쇄된 사진이 아닌 이미지 파일 형태로 발송해드립니다{" "}
              </div>
              <Fontsize30Weight800Margin10>
                🔥 필독 🔥
              </Fontsize30Weight800Margin10>
              <div>1. 1:1 주문제작 특성상 작품 전달 후 환불이 어렵습니다. </div>
              <div>
                2. 상업적으로 이용/2차 가공은 사전에 협의없이 불가합니다.{" "}
              </div>
            </FlexBoxBottomBox>
          </BorderNoneColorBox> */}
        </div>
        {/* <GuideTextBox>
          <div>❤️실사화가 아닌 귀여운 스타일로 3d 캐릭터를 그려드립니다❤️</div>
          <div>가족, 친구, 연인, 나에게 작은 선물은 어떠세요? </div>
          <br></br>
          <div>💖 상품 예시 💖</div>
          <div>(MOHA로고는 상품 전달 시 포함되지 않습니다.)</div>
        </GuideTextBox>
        <GuideImageBox>
          <FlexOne>
            <ProductImageBox src={testImage1} alt="상품이미지" />
          </FlexOne>
          <FlexOne>
            <ProductImageBox src={testImage2} alt="상품이미지" />
          </FlexOne>
        </GuideImageBox>
        <GuideImageBox>
          <FlexOne>
            <ProductImageBox src={testImage3} alt="상품이미지" />
          </FlexOne>
          <FlexOne>
            <ProductImageBox src={testImage4} alt="상품이미지" />
          </FlexOne>
        </GuideImageBox>
        <GuideTextBox>
          <div>💖 배경색 💖</div>
          <div>
            원하시는 배경색을 알려주세요. 따로 요청이 없으면 기본 파란계통
            배경색으로 제공됩니다. 배경으로 하고 싶은 이미지가 있으면
            보내주세요.{" "}
          </div>
        </GuideTextBox>
        <GuideImageBox2>
          <FlexOne>
            <ProductImageBox src={testImage5} alt="상품이미지" />
          </FlexOne>
          <FlexOne>
            <ProductImageBox src={testImage6} alt="상품이미지" />
          </FlexOne>
        </GuideImageBox2>
        <div>
          <GuideTextFinalBox>
            <GuideTextFinal>
              <Fontsize30Weight800Margin10>
                ❤️ 작업기간 ❤️
              </Fontsize30Weight800Margin10>
              <div>
                주문일로 부터 2영업일 이내 ✨실물 발송이 되지 않고 💛이메일💛로
                3개 작품 파일을 발송해드려요✨ ✨
              </div>
              <div>✨ 휴태폰 배경사이즈로 원하시면 미리 말씀해주세요</div>
              <br></br>
              <Fontsize30Weight800Margin10>
                💖 주문절차 💖{" "}
              </Fontsize30Weight800Margin10>
              <div>
                구매하기 → 아래 사항들을 보내주세요! → 완성후 메일로 이미지
                전송해드립니다
              </div>
              <div>✨ 사진 3장</div>
              <div>
                (두 눈을 뜨고 있고 헤어, 얼굴 전체가 확인 가능한 사진을 3장
                보내주시면 좋아요){" "}
              </div>
              <div>✨ 받으실 이메일 주소</div>
              <div>✨ 3개 작품 마다의 옷 스타일(3개 이내)</div>
              <div>
                따로 요청이 없으면 작가가 좋다고 느껴지는 스타일로 설정됩니다.
                (ex. 스쿨룩, 캐쥬얼, 의사 가운, 드레스, 정장 등)
              </div>
            </GuideTextFinal>
          </GuideTextFinalBox>
        </div> */}
        <div
          style={{
            height: `3rem`,
          }}
        ></div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
