import styled from "styled-components";

export const ListPageVhBox = styled.div`
  min-height: 80vh;
`;

export const ListPageTopSizeBox = styled.div`
  height: 5rem;
`;

export const ListPageTitleBox = styled.div`
  font-size: 20px;
  font-weight: 800;
`;

export const ListPageAllBox = styled.div`
  border: 1px solid rgba(33, 33, 33, 0.3);
  min-height: 10rem;
  border-radius: 8px;
  margin-top: 10px;
  padding: 20px;
  margin-bottom: 3rem;
`;

export const ListLoadingBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
`;
