import styled from "styled-components";

export const LoginTopViewBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 90vh;
`;

export const LoginSolidBox = styled.div`
  width: 500px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 4rem;
  border-radius: 8px;
`;

export const LoginTopIconBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LoginCursorBox = styled.div`
  cursor: pointer;
`;

export const LoginLogoBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
`;

export const LoginLogoInBox = styled.div`
  width: 15rem;
`;

export const LoginLogoImg = styled.img`
  width: 100%;
  height: 100%;
`;

export const LoginFontText = styled.div`
  font-size: 20px;
  font-weight: 800;
`;

export const EmailInputBox = styled.input`
  font-size: 15px;
  height: 30px;
  width: 100%;
  border: none;
  text-align: left;
  border-radius: 0%;
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
  outline: none;
  &::placeholder {
    text-align: left; /* placeholder 텍스트 왼쪽 정렬 */
  }
`;

export const PasswordInputBox = styled.input`
  font-size: 15px;
  height: 30px;
  width: 100%;
  border: none;
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
  outline: none;
  text-align: left;

  border-radius: 0%;

  &::placeholder {
    text-align: left; /* placeholder 텍스트 왼쪽 정렬 */
  }
`;

export const PasswordSecret = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const PasswordSecretBox = styled.div`
  border: none;
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
  outline: none;
  cursor: pointer;
`;

export const LoginFlexBox = styled.div`
  display: flex;
`;
