// export const Footer

import styled from "styled-components";

export const FooterDivBox = styled.div`
  width: 10rem;
`;

export const FooterImg = styled.img`
  filter: invert(1);
  width: 100%;
`;

export const FooterTextAlign = styled.div`
  text-align: start;
`;

export const FooterMdBox = styled.div`
  height: 3.7rem;
`;

export const FooterDivFlexBox = styled.div`
  display: flex;
  gap: 10px;
  justify-content: end;
  margin-bottom: 5px;
`;

export const FooterPointerBox = styled.div`
  cursor: pointer;
  font-size: 15px;
  font-weight: 800;
`;

export const FooterWMSvg = styled.svg`
  width: 10px;
  margin-right: 10px;
`;
