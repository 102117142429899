import api from "../../util/api";

export const LoginAPi = async (email, pw) => {
  try {
    const formData = new FormData();
    formData.append("id", email);
    formData.append("password", pw);

    const resp = await api.post("/auth/login", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return {
      data: resp,
      msg: "Success",
    };
  } catch (error) {
    console.log("로그인 실패", error);
    return {
      data: error,
      msg: "Fail",
    };
  }
};
