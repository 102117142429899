import { useNavigate } from "react-router-dom";
import mohalogo from "../image/logo.png";
import {
  FlexFont15Weight700Box,
  FlexHeight4Box,
  Gray003Bar,
  LogoImageBox,
  SizeBoxWidth20,
  TopBar,
  XxlContainerBox,
  XxlContainerColorBox,
} from "../style/AppStyle";
import { HeaderNavButton } from "./headerStyle";
import { getCookie, removeCookie } from "../util/cookies";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { removeToken } from "../redux/action/LoginAction";
import { resetAllAct } from "../redux/action/productAction";
import Swal from "sweetalert2";

export const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = getCookie("accessToken");
  const [isToken, setToken] = useState(false);
  // const cookie = useSelector((state) => state.cooke.token);
  const handleLogout = () => {
    // 쿠키에서 토큰 제거
    removeCookie("accessToken", { path: "/" });
    // 로그아웃 후 로그인 페이지로 리디렉션
    dispatch(removeToken());
    dispatch(resetAllAct());
    navigate("/");
  };

  const checkAndNavigate = (path, token) => {
    if (token) {
      navigate(path);
    } else {
      dispatch(removeToken());
      dispatch(resetAllAct());
      Swal.fire({
        title: `로그인이 필요합니다.`,
        icon: `error`,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/");
        }
      });
    }
  };

  const checkToken = () => {
    const token = getCookie("accessToken");
    setToken(!!token); // 토큰이 존재하면 true, 없으면 false
  };

  useEffect(() => {
    checkToken(); // 컴포넌트가 마운트될 때 토큰 상태 확인

    // 주기적으로 토큰 상태를 확인하는 타이머 설정
    const interval = setInterval(() => {
      checkToken();
    }, 1000); // 1초마다 토큰 상태 확인

    return () => clearInterval(interval); // 컴포넌트 언마운트 시 타이머 제거
  }, []);

  return (
    <div>
      <TopBar>
        <div className="container-xxl">
          <XxlContainerBox>
            <FlexHeight4Box></FlexHeight4Box>

            {!isToken ? (
              <HeaderNavButton onClick={() => navigate("/LoginPage")}>
                Login
              </HeaderNavButton>
            ) : (
              <div>
                <HeaderNavButton
                  onClick={() => checkAndNavigate("/ListPage", token)}
                >
                  구매목록
                </HeaderNavButton>
                <HeaderNavButton onClick={() => navigate("/")}>
                  상품목록
                </HeaderNavButton>
                <HeaderNavButton onClick={handleLogout}>
                  로그아웃
                </HeaderNavButton>
              </div>
            )}
          </XxlContainerBox>
        </div>
      </TopBar>{" "}
      <Gray003Bar>
        <XxlContainerColorBox className="container-xxl">
          <FlexHeight4Box>
            <LogoImageBox
              src={mohalogo}
              alt="logo"
              onClick={() => navigate("/")}
            />
          </FlexHeight4Box>
          <FlexFont15Weight700Box>
            {/* <CursorPointerBox>커뮤니티</CursorPointerBox> */}
            <SizeBoxWidth20></SizeBoxWidth20>
            {/* <CursorPointerBox>공지</CursorPointerBox> */}
          </FlexFont15Weight700Box>
        </XxlContainerColorBox>
      </Gray003Bar>
    </div>
  );
};
