export const SET_EMAIL = "SET_EMAIL";
export const SET_STYLE_ONE = "SET_STYLE_ONE";
export const SET_STYLE_TWO = "SET_STYLE_TWO";
export const SET_STYLE_THREE = "SET_STYLE_THREE";
export const SET_BACK_COLOR = "SET_BACK_COLOR";
export const SET_BACK_IMG = "SET_BACK_IMG";
export const SET_IMG_ONE = "SET_IMG_ONE";
export const SET_IMG_TWO = "SET_IMG_TWO";
export const SET_IMG_THREE = "SET_IMG_THREE";

export const ALL_RESET = "ALL_RESET";
export const PRODUCT_RESET = "PRODUCT_RESET";

export const PAY_TYPE = "PAY_TYPE";

export const setEmailAct = (email) => {
  return {
    type: SET_EMAIL,
    payload: email, // email을 payload로 전달
  };
};

export const setPayType = (payType) => {
  return {
    type: PAY_TYPE,
    payload: payType,
  };
};

export const setStyleOneAct = (styleOne) => {
  return {
    type: SET_STYLE_ONE,
    payload: styleOne,
  };
};

export const setStyleTwoAct = (styleTwo) => {
  return {
    type: SET_STYLE_TWO,
    payload: styleTwo,
  };
};

export const setStyleThreeAct = (styleThree) => {
  return {
    type: SET_STYLE_THREE,
    payload: styleThree,
  };
};

export const setColorAct = (color) => {
  return {
    type: SET_BACK_COLOR,
    payload: color,
  };
};

export const setBackImgAct = (backImg) => {
  return {
    type: SET_BACK_IMG,
    payload: backImg,
  };
};

export const setOneImgAct = (imgOne) => {
  return {
    type: SET_IMG_ONE,
    payload: imgOne,
  };
};

export const setTwoImgAct = (imgTwo) => {
  return {
    type: SET_IMG_TWO,
    payload: imgTwo,
  };
};

export const setThreeImgAct = (imgThree) => {
  return {
    type: SET_IMG_THREE,
    payload: imgThree,
  };
};

export const resetAllAct = () => {
  return {
    type: ALL_RESET,
  };
};

export const productResetAct = () => {
  return {
    type: PRODUCT_RESET,
  };
};
