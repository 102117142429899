import { useState } from "react";
import { useSelector } from "react-redux";
import { PayMentRepo } from "./repo/PaymentRepo";
import Swal from "sweetalert2";
import { ConditionsPage } from "../treaty/conditions/ConditionsPage";
import * as Dialog from '@radix-ui/react-dialog';
import * as RadioGroup from '@radix-ui/react-radio-group';
import { CreditCard } from "lucide-react";
import cardImg from "../image/card.png";
import kakaoImg from "../image/kakao.png";
import naverImg from "../image/naver.png";
import samsungImg from "../image/samsung.png";
import { useNavigate } from "react-router-dom";

const PayMentPageM = () => {
  const navigate = useNavigate();
  const backImg = useSelector((state) => state.backImg.backImg);
  const backColor = useSelector((state) => state.color);
  const email = useSelector((state) => state.email);
  const oneImg = useSelector((state) => state.oneImg.imgOne);
  const twoImg = useSelector((state) => state.twoImg.imgTwo);
  const threeImg = useSelector((state) => state.threeImg.imgThree);
  const styleOneImg = useSelector((state) => state.styleOne);
  const styleTwoImg = useSelector((state) => state.styleTwo);
  const styleThreeImg = useSelector((state) => state.styleThree);
  const token = useSelector((state) => state.cooke);

  const [payType, setPayType] = useState("creditcard");
  const [allAgree, setAllAgree] = useState(false);
  const [coffeePolicy, setCoffeePolicy] = useState(false);
  const [paymentPolicy, setPaymentPolicy] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showPaymentTermsModal, setShowPaymentTermsModal] = useState(false);

  const handleAllAgreeChange = (e) => {
    const isChecked = e.target.checked;
    setAllAgree(isChecked);
    setCoffeePolicy(isChecked);
    setPaymentPolicy(isChecked);
  };

  const base64ToFile = (base64String, filename) => {
    const [header, base64Data] = base64String.split(",");
    const mimeType = header.match(/:(.*?);/)[1];
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      byteArrays.push(new Uint8Array(byteNumbers));
    }

    const blob = new Blob(byteArrays, { type: mimeType });
    return new File([blob], filename, { type: mimeType });
  };

  const handlePayment = async () => {


    if (!coffeePolicy || !paymentPolicy) {
      Swal.fire({
        title: `이용약관에 동의가 필요합니다.`,
        icon: `error`,
      });
      return;
    }

    const formData = new FormData();
    formData.append("payType", payType);
    formData.append("email", email.email);
    formData.append("style1", styleOneImg.styleOne);
    formData.append("style2", styleTwoImg.styleTwo);
    formData.append("style3", styleThreeImg.styleThree);

    if (typeof oneImg === "string" && oneImg.startsWith("data:image")) {
      formData.append("file1", base64ToFile(oneImg, "image1.png"));
    }
    if (typeof twoImg === "string" && twoImg.startsWith("data:image")) {
      formData.append("file2", base64ToFile(twoImg, "image2.png"));
    }
    if (typeof threeImg === "string" && threeImg.startsWith("data:image")) {
      formData.append("file3", base64ToFile(threeImg, "image3.png"));
    }
    if (typeof backImg === "string" && backImg.startsWith("data:image")) {
      formData.append("backgroundFile", base64ToFile(backImg, "background.png"));
    }
    
    formData.append("backgroundColor", backColor.color);


    const resp = await PayMentRepo(formData, token);

    if (resp.msg === "Success") {

      const popup = window.open(
        resp.data.data, // 열려는 외부 페이지 URL
        "_blank", // 새 창으로 열기
        "width=800,height=600,scrollbars=yes" // 팝업 창 크기 및 옵션
      );
      const checkPopupClosed = setInterval(() => {
        if (popup.closed) {
          clearInterval(checkPopupClosed); // 팝업이 닫히면 interval을 제거
          navigate("/"); // "/" 경로로 이동
        }
      }, 1000); // 1초마다 팝업 상태를 확인

      // window.location.href = resp.data.data;

    
    }
    if (resp.msg === "Fail") {
      Swal.fire({
        title: `결제가 실패하였습니다.`,
        icon: `error`,
      });
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 p-4">
      <div className="max-w-2xl mx-auto">
        <div className="bg-white rounded-lg shadow-lg p-4">
          <div className="mb-2">
            <h1 className="text-lg font-bold">결제하기</h1>
            <p className="text-gray-600">안전하고 빠른 결제를 도와드립니다</p>
          </div>

          <div className="mb-2">
            <h2 className="text-lg font-bold mb-2">최종 결제 금액</h2>
            <div className="bg-blue-50 p-3 rounded-lg flex justify-between items-center">
              <span className="text-lg font-bold text-blue-900">₩5,000</span>
              <span className="bg-blue-100 text-blue-800 px-2 py-1 rounded-full text-sm">
                부가세 포함
              </span>
            </div>
          </div>

          <div className="mb-2">
            <h2 className="text-xl font-bold mb-2">결제 수단 선택</h2>
            <RadioGroup.Root value={payType} onValueChange={setPayType}>
              <div className="grid grid-cols-2 gap-2">
                <PaymentOption 
                  icon={cardImg}
                  title="신용카드"
                  value="creditcard"
                  selected={payType === 'creditcard'}
                />
                <PaymentOption 
                  icon={kakaoImg}
                  title="카카오페이"
                  value="kakaopay"
                  selected={payType === 'kakaopay'}
                />
                <PaymentOption 
                  icon={samsungImg}
                  title="삼성페이"
                  value="samsungpay"
                  selected={payType === 'samsungpay'}
                />
                <PaymentOption 
                  icon={naverImg}
                  title="네이버페이"
                  value="naverpay"
                  selected={payType === 'naverpay'}
                />
              </div>
            </RadioGroup.Root>
          </div>

          <div className="mb-6">
            <h2 className="text-xl font-bold mb-2">약관 동의</h2>
            <div className="border rounded-lg p-3 space-y-2">
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={allAgree}
                  onChange={handleAllAgreeChange}
                  className="rounded border-gray-300"
                />
                <span className="font-bold">전체 동의</span>
              </label>
              
              <hr className="my-2" />
              
              <div className="flex items-center justify-between">
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={coffeePolicy}
                    onChange={(e) => setCoffeePolicy(e.target.checked)}
                    className="rounded border-gray-300"
                  />
                  <span>이용약관</span>
                </label>
                <button
                  onClick={() => setShowTermsModal(true)}
                  className="text-blue-600 hover:text-blue-800"
                >
                  보기
                </button>
              </div>

              <div className="flex items-center justify-between">
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={paymentPolicy}
                    onChange={(e) => setPaymentPolicy(e.target.checked)}
                    className="rounded border-gray-300"
                  />
                  <span>결제 대행사 결제정보 위탁 동의</span>
                </label>
                <button
                  onClick={() => setShowPaymentTermsModal(true)}
                  className="text-blue-600 hover:text-blue-800"
                >
                  보기
                </button>
              </div>
            </div>
          </div>

          <button
            className={`w-full py-3 rounded-lg text-white font-bold text-base ${
              !coffeePolicy || !paymentPolicy
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-blue-600 hover:bg-blue-700'
            }`}
            disabled={!coffeePolicy || !paymentPolicy}
            onClick={handlePayment}
          >
            결제하기
          </button>
        </div>
      </div>

      <Dialog.Root open={showTermsModal} onOpenChange={setShowTermsModal}>
        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 bg-black/50" />
          <Dialog.Content className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-white rounded-lg shadow-lg p-6 max-w-2xl w-[90vw] max-h-[85vh] overflow-y-auto">
            <Dialog.Title className="text-xl font-bold mb-4">이용약관</Dialog.Title>
            <ConditionsPage />
            <div className="mt-4 flex justify-end">
              <button
                onClick={() => setShowTermsModal(false)}
                className="px-4 py-2 bg-gray-200 rounded-lg hover:bg-gray-300"
              >
                닫기
              </button>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>

      <Dialog.Root open={showPaymentTermsModal} onOpenChange={setShowPaymentTermsModal}>
        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 bg-black/50" />
          <Dialog.Content className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-white rounded-lg shadow-lg p-6 max-w-2xl w-[90vw] max-h-[85vh] overflow-y-auto">
            <Dialog.Title className="text-xl font-bold mb-4">
              결제 대행사 결제정보 위탁 동의 약관
            </Dialog.Title>
            <div className="space-y-3">
              <p>● 목적</p>
              <p>
                1. 결제 수단 처리 및 요금 정산(정보통신망 이용촉진 및 정보 보호
                등에 관련 법률에 의거)
              </p>
              <p>2. 부정결제 검증 및 방지</p>
              {/* 나머지 약관 내용 */}
            </div>
            <div className="mt-4 flex justify-end">
              <button
                onClick={() => setShowPaymentTermsModal(false)}
                className="px-4 py-2 bg-gray-200 rounded-lg hover:bg-gray-300"
              >
                닫기
              </button>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </div>
  );
};

const PaymentOption = ({ icon, title, value, selected }) => (
  <RadioGroup.Item value={value} className="w-full">
    <div
      className={`p-2 rounded-lg border transition-all cursor-pointer h-full flex flex-col justify-center items-center ${
        selected ? 'border-blue-500 bg-blue-50' : 'border-gray-200 hover:border-blue-200'
      }`}
    >
      <div className="flex flex-col items-center space-y-1">
        <img src={icon} alt={title} className="w-8 h-3" />
        <span className="font-medium text-sm">{title}</span>
      </div>
    </div>
  </RadioGroup.Item>
);


export default PayMentPageM;
