import styled from "styled-components";

export const HeaderNavButton = styled.button`
  border: none;
  background: transparent;
  color: #fff;
  cursor: pointer;
  font-weight: 800;
  margin: 0 10px; // 버튼 사이의 간격 추가
  padding: 5px 10px; // 버튼 자체의 패딩 추가
`;
