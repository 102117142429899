import axios from "axios";
import { API_URL } from "./const";
import { getCookie } from "./cookies";

const api = axios.create({
  baseURL: "",
});

api.interceptors.request.use(
  (config) => {
    // config.headers["API-Key"] = "COFFEE1234!!!";
    // config.headers["Access-Control-Allow-Origin"] = "*";
    if (config.headers.requiresAuth !== false) {
      const token = getCookie("accessToken");
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
    }
    delete config.headers.requiresAuth;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },

  (error) => {
    if (error.response && [401, 403].includes(error.response.status)) {
      // const dispatch = useDispatch();
      // removeCookie("accessToken");
      // const navigate = useNavigation();
      // if (navigate) {
      //   dispatch(resetStore());
      //   navigate("/renewalLogin");
      // }
      //   store.dispatch(authError(error.response.status));
    }
    return Promise.reject(error);
  }
);

export default api;
