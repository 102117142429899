import styled from "styled-components";

export const SingUpPageButton = styled.button`
  border: 1px solid #ff9900;
  margin-top: 2rem;
  border-radius: 8px;
  background-color: #ff9900;
  width: 100%;
  height: 2.5rem;
  color: #fff;
  font-weight: 800;
  transition: 0.35s;

  &:hover {
    background-color: #fff;
    color: #ff9900;
    border: 1px solid #ff9900;
  }
`;
