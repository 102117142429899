import {
  ALL_RESET,
  PAY_TYPE,
  PRODUCT_RESET,
  SET_BACK_COLOR,
  SET_BACK_IMG,
  SET_EMAIL,
  SET_IMG_ONE,
  SET_IMG_THREE,
  SET_IMG_TWO,
  SET_STYLE_ONE,
  SET_STYLE_THREE,
  SET_STYLE_TWO,
} from "../action/productAction";

const emailState = {
  email: null,
};

export const emailReducer = (state = emailState, action) => {
  if (!action || !action.type) {
    console.error("액션 객체가 잘못되었습니다.", action);
    return state; // 상태를 그대로 반환하거나 기본값을 반환
  }

  switch (action.type) {
    case SET_EMAIL:
      return {
        ...state,
        email: action.payload,
      };
    case ALL_RESET:
      return {
        ...state,
        email: null,
      };
    case PRODUCT_RESET:
      return {
        ...state,
        email: null,
      };
    default:
      return state;
  }
};

const styleStateOne = {
  styleOne: null,
};

export const styleOneReducer = (state = styleStateOne, action) => {
  switch (action.type) {
    case SET_STYLE_ONE:
      return {
        styleOne: action.payload,
      };
    case ALL_RESET:
      return {
        styleOne: null,
      };
    case PRODUCT_RESET:
      return {
        styleOne: null,
      };
    default:
      return state;
  }
};

const styleStateTwo = {
  styleTwo: null,
};

export const styleTwoReducer = (state = styleStateTwo, action) => {
  switch (action.type) {
    case SET_STYLE_TWO:
      return {
        styleTwo: action.payload,
      };
    case ALL_RESET:
      return {
        styleTwo: null,
      };
    case PRODUCT_RESET:
      return {
        styleTwo: null,
      };
    default:
      return state;
  }
};

const styleStateThree = {
  styleThree: null,
};

export const styleThreeReducer = (state = styleStateThree, action) => {
  switch (action.type) {
    case SET_STYLE_THREE:
      return {
        styleThree: action.payload,
      };
    case ALL_RESET:
      return {
        styleThree: null,
      };
    case PRODUCT_RESET:
      return {
        styleThree: null,
      };
    default:
      return state;
  }
};

const colorState = {
  color: null,
};

export const colorReducer = (state = colorState, action) => {
  switch (action.type) {
    case SET_BACK_COLOR:
      return {
        color: action.payload,
      };
    case ALL_RESET:
      return {
        color: null,
      };
    case PRODUCT_RESET:
      return {
        color: null,
      };
    default:
      return state;
  }
};

const backImgState = {
  backImg: null,
};

export const backImgReducer = (state = backImgState, action) => {
  switch (action.type) {
    case SET_BACK_IMG:
      return {
        backImg: action.payload,
      };
    case ALL_RESET:
      return {
        backImg: null,
      };
    case PRODUCT_RESET:
      return {
        backImg: null,
      };
    default:
      return state;
  }
};

const setImgOneState = {
  imgOne: null,
};

export const oneImgReducer = (state = setImgOneState, action) => {
  switch (action.type) {
    case SET_IMG_ONE:
      return {
        imgOne: action.payload,
      };
    case ALL_RESET:
      return {
        imgOne: null,
      };
    case PRODUCT_RESET:
      return {
        imgOne: null,
      };
    default:
      return state;
  }
};

const setImgTwoState = {
  imgTwo: null,
};

export const twoImgReducer = (state = setImgTwoState, action) => {
  switch (action.type) {
    case SET_IMG_TWO:
      return {
        imgTwo: action.payload,
      };
    case ALL_RESET:
      return {
        imgTwo: null,
      };
    case PRODUCT_RESET:
      return {
        imgTwo: null,
      };
    default:
      return state;
  }
};

const setImgThreeState = {
  imgThree: null,
};

export const threeImgReducer = (state = setImgThreeState, action) => {
  switch (action.type) {
    case SET_IMG_THREE:
      return {
        imgThree: action.payload,
      };
    case ALL_RESET:
      return {
        imgThree: null,
      };
    case PRODUCT_RESET:
      return {
        imgThree: null,
      };
    default:
      return state;
  }
};

const setPayTypeState = {
  payType: null,
};

export const payTypeReducer = (state = setPayTypeState, action) => {
  switch (action.type) {
    case PAY_TYPE:
      return {
        payType: action.payload,
      };
    case ALL_RESET:
      return {
        payType: null,
      };
    case PRODUCT_RESET:
      return {
        payType: null,
      };
    default:
      return state;
  }
};
