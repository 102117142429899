import styled from "styled-components";

export const LoginButton = styled.button`
  flex: 1;
  border: 1px solid #ffaa77;
  height: 38px;
  background-color: #ffaa77;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
  transition: 0.35s;

  &:hover {
    background-color: #fff;
    color: #ffaa77;
    border: 1px solid #ffaa77;
  }
`;

export const SingUpButton = styled.button`
  flex: 1;
  border: 1px solid #ff9900;
  height: 38px;
  background-color: #ff9900;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
  transition: 0.35s;

  &:hover {
    background-color: #fff;
    color: #ff9900;
    border: 1px solid #ff9900;
  }
`;
