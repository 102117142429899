import { Footer } from "../footer/footer";
import { Header } from "../header/header";
import { SizeTreatyBox } from "../style/AppStyle";
import { ConditionsPage } from "./conditions/ConditionsPage";

export const TreatyPage = () => {
  return (
    <div>
      <Header></Header>
      <div className="container">
        <div className="container-fluid">
          <div className="container-sm">
            <SizeTreatyBox></SizeTreatyBox>
            <ConditionsPage />
            <SizeTreatyBox></SizeTreatyBox>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};
