import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import App from "./App";
import { LoginPage } from "./login/LoginPage";
import { SingUpPage } from "./singup/SingUpPage";
import { GlobalStyle } from "./style/AppStyle";
import PayMentPage from "./payment/PaymentPage";
import { ListPage } from "./list/Listpage";
import { TreatyPage } from "./treaty/TreatyPage";
import { ToInformationPage } from "./treaty/ToInformationPage";
import { getCookie } from "./util/cookies";
import PayMentPageM from "./payment/PaymentPageM";

function Home() {
  return (
    <>
      <GlobalStyle />
      <div className="Home">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<App />}></Route>
            <Route path="/LoginPage" element={<LoginPage />} />
            <Route path="/SingUpPage" element={<SingUpPage />} />
            <Route path="/ListPage" element={<ListPage />} />
            <Route path="/treatyPage" element={<TreatyPage />} />
            <Route path="/informationPage" element={<ToInformationPage />} />
            <Route path="/payment" element={<PayMentPage />}></Route>
            <Route path="/paymentM" element={<PayMentPageM />}></Route>
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
}

export default Home;
