// export const API_URL = "http://xraidev.store:8300";
export const API_URL = "https://moha.ai";

export const formatKoreanPhoneNumber = (phoneNumber) => {
  // 숫자만 남기기
  const cleanNumber = phoneNumber.replace(/[^0-9]/g, "");

  // 전화번호가 11자리인 경우 (앞에 0을 제거한 후 10자리로 변환)
  if (cleanNumber.length === 11 && cleanNumber.startsWith("010")) {
    return cleanNumber.replace(/^010(\d{4})(\d{4})$/, "10-$1-$2");
  }

  // 전화번호가 10자리인 경우 (지역번호 2자리)
  if (cleanNumber.length === 10) {
    return cleanNumber.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3");
  }

  // 그 외: 변환하지 않음
  return phoneNumber;
};

export function validatePassword(password) {
  // 길이 검증
  if (password.length < 8 || password.length > 16) {
    return false;
  }
  return true;
}

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const base64ToBlob = (base64Str) => {
  const byteCharacters = atob(base64Str.split(",")[1]); // base64에서 ',' 이후 부분만 추출
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset++) {
    byteArrays.push(byteCharacters.charCodeAt(offset));
  }

  const blob = new Blob([new Uint8Array(byteArrays)], { type: "image/png" }); // MIME 타입 설정
  return blob;
};

export const base64ToFile = (base64Str, fileName = "image.png") => {
  const blob = base64ToBlob(base64Str);
  const file = new File([blob], fileName, { type: "image/png" });
  return file;
};
