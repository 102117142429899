import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import { legacy_createStore as createStore } from "redux";
import storage from "redux-persist/lib/storage";
import authReducer from "./reducer/LoginReducer";
import tokenReducer from "./reducer/LoginReducer";
import {
  backImgReducer,
  colorReducer,
  emailReducer,
  oneImgReducer,
  payTypeReducer,
  styleOneReducer,
  styleThreeReducer,
  styleTwoReducer,
  threeImgReducer,
  twoImgReducer,
} from "./reducer/ProductReducer";

const rootReducer = combineReducers({
  cooke: tokenReducer,
  email: emailReducer,
  styleOne: styleOneReducer,
  styleTwo: styleTwoReducer,
  styleThree: styleThreeReducer,
  color: colorReducer,
  backImg: backImgReducer,
  oneImg: oneImgReducer,
  twoImg: twoImgReducer,
  threeImg: threeImgReducer,
  payType: payTypeReducer,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer);
const persistor = persistStore(store);

export { store, persistor };
