import api from "../../util/api";

export const PayMentRepo = async (data, token) => {
  try {
    const resp = await api.post("/donation", data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`, // 토큰을 헤더에 포함시킴
      },
    });

    return {
      data: resp,
      msg: "Success",
    };
  } catch (error) {
    console.log("페이면트 에러", error);
    return {
      data: error,
      msg: "Fail",
    };
  }
};
