import { useNavigate } from "react-router-dom";
import { IoHomeOutline } from "react-icons/io5";
import { FaArrowLeft } from "react-icons/fa";
import logo from "../image/logo.png";
import { IoEyeOffSharp } from "react-icons/io5";
import { LoginButton, SingUpButton } from "../style/LoginStyle";
import { Footer } from "../footer/footer";
import { FaRegEye } from "react-icons/fa";

import {
  EmailInputBox,
  LoginCursorBox,
  LoginFlexBox,
  LoginFontText,
  LoginLogoBox,
  LoginLogoImg,
  LoginLogoInBox,
  LoginSolidBox,
  LoginTopIconBox,
  LoginTopViewBox,
  PasswordInputBox,
  PasswordSecret,
  PasswordSecretBox,
} from "./LogintStyle";
import {
  SizeBoxHeight05Rem,
  SizeBoxHeight15Rem,
  SizeBoxHeight1Rem,
  SizeBoxWidth1Rem,
} from "../style/AppStyle";
import { useState } from "react";
import { emailRegex } from "../util/const";
import { SingUpErrorMSG } from "../singup/SingupStyle";
import { LoginAPi } from "./repo/LoginRepo";
import Swal from "sweetalert2";
import { setCookie } from "../util/cookies";
import { PulseLoader } from "react-spinners";
import { useDispatch } from "react-redux";
import { setToken } from "../redux/action/LoginAction";

export const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isEmail, setEmail] = useState("");
  const [isPassword, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false); // 비밀번호 표시 여부 상태
  const [isLoader, setLoader] = useState(false);
  // 유효성검사 확인
  const [isEmailValue, setEmailValue] = useState("0");

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible); // 상태 변경
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = async (email, pw) => {
    setLoader(true);
    //이메일 형식이 틀릴떄
    if (!emailRegex.test(email)) {
      // 정규식에 매칭되지 않을 때
      setEmailValue("1");
    } else {
      // 정규식에 매칭될 때
      setEmailValue("2");
    }

    const resp = await LoginAPi(email, pw);
    if (resp.msg === "Success") {
      setEmailValue("0");
      setCookie("accessToken", resp.data.data.accessToken, {
        path: "/",
        maxAge: 3600,
      });

      dispatch(setToken(resp.data.data.accessToken));
      setLoader(false);
      navigate("/");
    } else {
      if (resp.msg === "Fail") {
        setLoader(false);
        if (resp.data.response.data.message === "잘못된 아이디 혹은 비밀번호") {
          Swal.fire({
            title: `이메일 또는 비밀번호를\n확인해주세요.`,
            icon: `error`,
          });
        }
      }
      setLoader(false);
      return;
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleLogin(isEmail, isPassword);
    }
  };

  return (
    <div>
      <div className="container">
        <div className="container-fluid">
          <div className="container-sm">
            <div></div>
            <LoginTopViewBox>
              <LoginSolidBox>
                <LoginTopIconBox>
                  <LoginCursorBox onClick={() => navigate("/")}>
                    <FaArrowLeft size={20} />
                  </LoginCursorBox>
                  <LoginCursorBox onClick={() => navigate("/")}>
                    <IoHomeOutline size={20} />
                  </LoginCursorBox>
                </LoginTopIconBox>
                <LoginLogoBox>
                  <LoginLogoInBox>
                    <LoginLogoImg src={logo} alt="로고" />
                  </LoginLogoInBox>
                </LoginLogoBox>
                <LoginFontText>로그인</LoginFontText>
                <SizeBoxHeight1Rem />
                <div>
                  <EmailInputBox
                    type="text"
                    placeholder="이메일"
                    value={isEmail}
                    onChange={handleEmail}
                    onKeyDown={handleKeyPress} // Enter 키 감지
                  ></EmailInputBox>
                </div>
                {isEmailValue === "1" && (
                  <SingUpErrorMSG>유효하지 않은 이메일입니다.</SingUpErrorMSG>
                )}
                <SizeBoxHeight05Rem />
                <LoginFlexBox>
                  <PasswordInputBox
                    type={passwordVisible ? "text" : "password"}
                    placeholder="비밀번호"
                    value={isPassword}
                    onChange={handlePassword}
                    onKeyDown={handleKeyPress} // Enter 키 감지
                  ></PasswordInputBox>
                  <PasswordSecretBox onClick={togglePasswordVisibility}>
                    {passwordVisible ? (
                      <FaRegEye size={20} />
                    ) : (
                      <IoEyeOffSharp size={20} />
                    )}
                  </PasswordSecretBox>
                </LoginFlexBox>

                <SizeBoxHeight15Rem />
                <PasswordSecret>
                  <LoginButton
                    disabled={isLoader}
                    onClick={() => handleLogin(isEmail, isPassword)}
                  >
                    {isLoader ? (
                      <PulseLoader color="#fff" size={10} />
                    ) : (
                      "로그인"
                    )}
                  </LoginButton>
                  <SizeBoxWidth1Rem />
                  <SingUpButton onClick={() => navigate("/SingUpPage")}>
                    회원가입
                  </SingUpButton>
                </PasswordSecret>
              </LoginSolidBox>
            </LoginTopViewBox>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};
