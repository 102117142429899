import styled from "styled-components";

export const SingUpTopBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: auto;
  min-height: 100vh;
`;

export const SingUpWidthBox = styled.div`
  width: 440px;
  margin-top: 5rem;
  margin-bottom: 5rem;
`;

export const SingUpIconBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SingUpNavBox = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const SingUpBackButtonBox = styled.div`
  margin-left: 10px;
  font-size: 16px;
  font-weight: 700;
`;

export const SingUpCursorBox = styled.div`
  cursor: pointer;
`;

export const SingUpGuidTextBox = styled.div`
  display: flex;
  justify-content: start;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  flex-direction: column;
`;

export const SingUpGuidTextBox2 = styled.div`
  font-size: 23px;
  font-weight: 800;
  margin-bottom: 10px;
`;

export const SingUpInputTitleBox = styled.div`
  font-size: 18px;
  font-weight: 700;
`;

export const SingUpInputBox = styled.input`
  width: 100%;
  border: none;
  border-bottom: 1px solid rgba(33, 33, 33, 0.3);
  border-radius: 0%;
  text-align: left;

  outline: none;
  &::placeholder {
    text-align: left; /* placeholder 텍스트 왼쪽 정렬 */
  }
`;

export const SingUpCheckBox = styled.input`
  margin-right: 10px;
`;

export const SingUpFlexBox = styled.div`
  display: flex;
  align-items: center;
`;

export const SingUpInputMarginTopBox = styled.div`
  margin-top: 10px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  height: 15rem;
  border-radius: 8px;
  padding: 10px;
  overflow-y: scroll;
`;

export const SingUpCheckBoxText = styled.div`
  font-size: 15px;
  font-weight: 500;
`;

export const SingUpErrorMSG = styled.div`
  margin-top: 5px;
  font-size: 12px;
  color: red;
`;
