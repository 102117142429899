import { useEffect, useState } from "react";
import defaultImg from "../image/logo.png";
import {
  ListBoxBackBox,
  ListBoxBackground,
  ListBoxBorderStyled,
  ListBoxColorBox,
  ListBoxColorDataBox,
  ListBoxContainerBox,
  ListBoxContainerBoxThree,
  ListBoxContainerBoxTwo,
  ListBoxContainerInBoxOne,
  ListBoxFlex3,
  ListBoxImgBBOX,
  ListBoxImgBox,
  ListBoxImgOne,
  ListBoxSizeBoxHeight03,
  ListBoxSpanOne,
  ListBoxStyledBox,
  ListBoxTextBox,
  ListBoxTextBoxTwo,
  ListBoxTextOne,
  ListBoxTopBox,
} from "./ListBoxStyle";
import { API_URL } from "../util/const";
import { HashLoader } from "react-spinners";

export const colorMap = {
  red: "#FF0000",
  Blue: "#0000FF",
  green: "#008000",
  yellow: "#FFFF00",
  orange: "#FFA500",
  purple: "#800080",
};

export const ListBox = ({ data }) => {
  const [formattedDate, setFormattedDate] = useState("");
  const [imgLoading, setImgLoading] = useState(false);
  const backgroundColor = colorMap[data.background_color] || "#fff"; // 기본값은 검정색
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // 화면 크기 변경을 실시간으로 추적
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // resize 이벤트 리스너 추가
    window.addEventListener("resize", handleResize);

    // 컴포넌트 언마운트 시 리스너 제거
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const imageUrl1 = `${API_URL}${data.file_path_1}`;
  const imageUrl2 = `${API_URL}${data.file_path_2}`;
  const imageUrl3 = `${API_URL}${data.file_path_3}`;
  const backImgUrl = `${API_URL}${data.background_file_path}`;

  useEffect(() => {
    if (data.create_at) {
      const date = new Date(data.create_at);
      const formatted = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")} ${date
        .getHours()
        .toString()
        .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
      setFormattedDate(formatted);
    }
  }, [data.create_at]);

  // 이미지 로드 체크
  useEffect(() => {
    const checkImagesLoaded = async () => {
      const images = [imageUrl1, imageUrl2, imageUrl3, backImgUrl];
      const loadImage = (url) =>
        new Promise((resolve) => {
          const img = new Image();
          img.src = url;
          img.onload = resolve;
          img.onerror = resolve; // 에러 발생 시에도 resolve 호출
        });

      await Promise.all(images.map(loadImage));
      setImgLoading(false); // 모든 이미지가 로드되면 로딩 종료
    };

    checkImagesLoaded();
  }, [imageUrl1, imageUrl2, imageUrl3, backImgUrl]);

  return (
    <div>
      {imgLoading ? (
        <ListBoxColorBox>
          <HashLoader size={200} color="#205929" />
        </ListBoxColorBox>
      ) : (
        <>
          <ListBoxTopBox>{formattedDate}</ListBoxTopBox>
          <ListBoxBorderStyled>
            <div>
              {data.pay_state === 2 ? (
                <ListBoxSpanOne>결재완료</ListBoxSpanOne>
              ) : (
                <ListBoxSpanOne>전송완료</ListBoxSpanOne>
              )}
              {/* <ListBoxSpanTwo>6/9(일) 도착</ListBoxSpanTwo> */}
            </div>
            <ListBoxContainerBox>
              <ListBoxImgBBOX>
                <ListBoxImgBox>
                  <div>
                    <ListBoxImgOne
                      onError={(e) => {
                        e.target.src = defaultImg;
                      }}
                      src={imageUrl1}
                    ></ListBoxImgOne>
                  </div>

                  {data.file_path_2 !== "" ? (
                    <ListBoxImgOne
                      onError={(e) => {
                        e.target.src = defaultImg;
                      }}
                      src={imageUrl2}
                    ></ListBoxImgOne>
                  ) : (
                    <></>
                  )}
                  {data.file_path_3 !== "" ? (
                    <ListBoxImgOne
                      onError={(e) => {
                        e.target.src = defaultImg;
                      }}
                      src={imageUrl3}
                    ></ListBoxImgOne>
                  ) : (
                    <></>
                  )}
                </ListBoxImgBox>
              </ListBoxImgBBOX>

              <ListBoxContainerBoxTwo>
                <ListBoxFlex3>
                  <ListBoxTextOne>{data.product_name}</ListBoxTextOne>
                  <div>
                    {data.pgcode} {data.amount} 원 1개
                  </div>
                  <ListBoxTextBox>이메일</ListBoxTextBox>
                  <div>{data.e_mail}</div>
                </ListBoxFlex3>
                {windowWidth >= 995 && (
                  <ListBoxContainerBoxThree>
                    <ListBoxContainerInBoxOne>
                      <ListBoxTextBoxTwo>옷스타일</ListBoxTextBoxTwo>
                      {data.style_1 == "undefined" ? (
                        <></>
                      ) : (
                        <ListBoxStyledBox>{data.style_1}</ListBoxStyledBox>
                      )}
                      <ListBoxSizeBoxHeight03></ListBoxSizeBoxHeight03>
                      {data.style_2 == "undefined" ? (
                        <></>
                      ) : (
                        <ListBoxStyledBox>{data.style_2}</ListBoxStyledBox>
                      )}
                      <ListBoxSizeBoxHeight03></ListBoxSizeBoxHeight03>
                      {data.style_3 == "undefined" ? (
                        <></>
                      ) : (
                        <ListBoxStyledBox>{data.style_3}</ListBoxStyledBox>
                      )}{" "}
                    </ListBoxContainerInBoxOne>
                    <ListBoxBackground>
                      <ListBoxTextBoxTwo>배경</ListBoxTextBoxTwo>
                      <ListBoxBackBox>
                        {data.background_color !== "" ? (
                          <ListBoxColorBox>
                            {/* */}
                            <ListBoxColorDataBox
                              bgColor={data.background_color}
                            >
                              <div>{data.background_color}</div>
                            </ListBoxColorDataBox>
                          </ListBoxColorBox>
                        ) : (
                          <ListBoxImgOne src={backImgUrl} alt="" />
                        )}
                      </ListBoxBackBox>
                    </ListBoxBackground>
                  </ListBoxContainerBoxThree>
                )}
              </ListBoxContainerBoxTwo>
            </ListBoxContainerBox>
            {windowWidth <= 995 && (
              <ListBoxContainerBoxThree>
                <ListBoxContainerInBoxOne>
                  <ListBoxTextBoxTwo>옷스타일</ListBoxTextBoxTwo>
                  {data.style_1 == "undefined" ? (
                    <></>
                  ) : (
                    <ListBoxStyledBox>{data.style_1}</ListBoxStyledBox>
                  )}
                  <ListBoxSizeBoxHeight03></ListBoxSizeBoxHeight03>
                  {data.style_2 == "undefined" ? (
                    <></>
                  ) : (
                    <ListBoxStyledBox>{data.style_2}</ListBoxStyledBox>
                  )}
                  <ListBoxSizeBoxHeight03></ListBoxSizeBoxHeight03>
                  {data.style_3 == "undefined" ? (
                    <></>
                  ) : (
                    <ListBoxStyledBox>{data.style_3}</ListBoxStyledBox>
                  )}{" "}
                </ListBoxContainerInBoxOne>
                <ListBoxBackground>
                  <ListBoxTextBoxTwo>배경</ListBoxTextBoxTwo>
                  <ListBoxBackBox>
                    {data.background_color !== "" ? (
                      <ListBoxColorBox>
                        {/* */}
                        <ListBoxColorDataBox bgColor={data.background_color}>
                          <div>{data.background_color}</div>
                        </ListBoxColorDataBox>
                      </ListBoxColorBox>
                    ) : (
                      <ListBoxImgOne
                        onError={(e) => {
                          e.target.src = defaultImg;
                        }}
                        src={backImgUrl}
                        alt=""
                      />
                    )}
                  </ListBoxBackBox>
                </ListBoxBackground>
              </ListBoxContainerBoxThree>
            )}
          </ListBoxBorderStyled>
        </>
      )}
    </div>
  );
};
