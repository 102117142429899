import styled, { createGlobalStyle } from "styled-components";

export const TopBar = styled.div`
  background-color: #ffaa77;
  height: 38px;
`;

export const XxlContainerBox = styled.div`
  height: 38px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-size: 12px;
`;

export const ThreeButtonSpaceBox = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: 40px;
  font-size: 12px;
  margin-bottom: 2rem;
`;

export const ThreeTwoButtonBox = styled.button`
  flex: 1;
  border: 1px solid rgba(33, 33, 33, 0.1);
  background-color: rgba(33, 33, 33, 0.03);
`;

export const ThreeOneButtonBox = styled.button`
  flex: 1;
  border: 1px solid rgba(33, 33, 33, 0.1);
  background-color: rgba(33, 33, 33, 0.1);
`;

export const BorderNoneColorBox = styled.div`
  border: none;
  background-color: rgba(33, 33, 33, 0.05);
`;

export const FlexBoxBottomBox = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 30px;
  flex-direction: column;
  font-size: 18px;
`;

export const Fontsize30Weight800Margin10 = styled.div`
  font-size: 30px;
  font-weight: 800;
  margin-bottom: 10px;
`;

export const XxlContainerColorBox = styled.div`
  height: 4rem;
  align-items: center;
  display: flex;
  justify-content: space-between;
  color: #205929;
`;

export const Gray003Bar = styled.div`
  background-color: rgba(33, 33, 33, 0.03);
  border-bottom: 1px solid rgba(33, 33, 33, 0.1);
  height: rem;
`;

export const FlexHeight4Box = styled.div`
  display: flex;
  height: 4rem;
  align-items: center;
`;

export const FlexPadding3Box = styled.div`
  display: flex;
  padding-top: 3rem;
`;

export const FlexBox = styled.div`
  display: flex;
`;

export const TextColorGrayBox = styled.div`
  color: gray;
`;

export const ProductTopFlexBox = styled.div`
  display: flex;
  flex: 1;
  height: 40rem;
  padding-top: 1rem;

  @media (max-width: 850px) {
    height: 100%;
  }
`;

export const ProductLeftFlexBox = styled.div`
  flex: 1;
  display: flex;
  margin-right: 3rem;
`;

export const ProductLeftFlexBox2 = styled.div`
  flex: 1;
  display: flex;
`;

export const ProductRightFlexBox = styled.div`
  flex: 1;
  display: flex;
  margin-left: 3rem;
  flex-direction: column;
`;

export const ProductRightFlexBox2 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
export const FlexFont15Weight700Box = styled.div`
  display: flex;
  font-size: 15px;
  font-weight: 700;
`;

export const FlexFont18Box = styled.div`
  display: flex;
  background-color: #fff;
  align-items: center;
  font-size: 18px;
`;

export const SizeBoxWidth20 = styled.div`
  width: 20px;
`;

export const FontSize18pxBox = styled.div`
  font-size: 18px;
`;

export const SizeBoxWidth02Rem = styled.div`
  width: 0.2rem;
`;

export const SizeBoxHeight15Rem = styled.div`
  height: 1.5rem;
`;

export const SizeBoxHeight1Rem = styled.div`
  height: 1rem;
`;
export const SizeBoxHeight05Rem = styled.div`
  height: 0.5rem;
`;

export const SizeBoxHeight2Rem = styled.div`
  height: 2rem;
`;

export const SizeBoxWidth2Rem = styled.div`
  width: 2rem;
`;

export const SizeBoxWidth1Rem = styled.div`
  width: 1rem;
`;

export const FlexMargiTop2remBox = styled.div`
  display: flex;
  margin-top: 2rem;
`;

export const FontGraySize12Box = styled.div`
  color: gray;
  font-size: 12px;
`;

export const FontGraySize15Box = styled.div`
  color: gray;
  font-size: 15px;
`;

export const FontMarginLeftBox = styled.div`
  font-size: 15px;
  margin-left: 1rem;
`;

export const ColorBlackWeight800 = styled.div`
  color: black;
  font-weight: 800;
`;

export const Font18Weight800Margin = styled.div`
  font-size: 18px;
  font-weight: 800;
  margin-left: 1rem;
`;

export const FlexSBBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const KuponButton = styled.button`
  margin-right: 1.5rem;
  border: none;
  border-radius: 8px;
  background-color: #205929;
  height: 2rem;
  color: #fff;
  font-weight: 600;
  font-size: 13px;
  text-align: center;
`;

export const ProductTextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 12px;
  color: gray;
`;

export const PayBox = styled.div`
  border: 1px solid rgba(33, 33, 33, 0.1);
  height: 5rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const OptionBox = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(33, 33, 33, 0.1);
  height: 20rem; /* 고정된 높이 */
  border-radius: 8px;
  align-items: flex-start; /* 수평 방향에서 왼쪽 정렬 */
  justify-content: flex-start; /* 수직 방향에서 위쪽 정렬 */
  overflow: auto; /* 스크롤이 가능하도록 설정 */
  padding: 0.5rem; /* 스크롤 시 내용이 보더와 겹치지 않도록 패딩 추가 */
  /* 스크롤바 스타일 */
  &::-webkit-scrollbar {
    width: 8px; /* 스크롤바 너비 */
  }

  &::-webkit-scrollbar-track {
    background: rgba(33, 33, 33, 0.1); /* 스크롤바 트랙(뒷배경) 색상 */
  }

  &::-webkit-scrollbar-thumb {
    background: #ffaa77; /* 스크롤바 손잡이 색상 */
    border-radius: 4px; /* 손잡이 모서리 둥글게 */
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #f68063; /* 손잡이 호버 시 색상 */
  }
`;

export const ThreeButtonBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  widows: 100%;
  gap: 10px;
`;

export const FlexBoxMarginTop05Bottom3 = styled.div`
  display: flex;
  margin-top: 0.5rem;
  margin-bottom: 3rem;
`;

export const MineImageBox = styled.div`
  height: 5rem;
  width: 5rem;
  cursor: pointer;
`;

export const ImageMineBox = styled.img`
  width: 100%;
  height: 100%;
  transition: all 0.5s ease;
`;

export const ImageMineBox2 = styled.div`
  width: 5rem;
  height: 5rem;
  cursor: pointer;
`;

export const SizeTreatyBox = styled.div`
  height: 5rem;
`;

export const LineThroughBox = styled.span`
  text-decoration: line-through;
`;

export const SpanColorRedBox = styled.span`
  padding-right: 0.03rem;
  font-size: 15px;
  color: #fe5356;
  margin-right: 5px;
`;

export const SpanTextBox = styled.span`
  font-size: 15px;
  color: gray;
  text-decoration: line-through; /* 텍스트에 줄 긋기 */
  /* font-weight: 400; */
  margin-right: 5px;
`;

export const SpanTextBox2 = styled.span`
  font-size: 15px;
  /* color: gray; */
  font-weight: 400;
`;

export const CursorPointerBox = styled.div`
  cursor: pointer;
`;

export const CursorPointerWidthBox = styled.div`
  width: 35px;
  cursor: pointer;
`;

export const LogoImageBox = styled.img`
  max-height: 50%;
  height: 50%;
  cursor: pointer;
`;

export const ProductImageBox = styled.img`
  width: 100%;
  height: 100%;
`;

export const ButtonGaide = styled.div`
  display: flex;
  color: white;
  border: 1px solid black;
  background-color: black;
  height: 2rem;
  width: 10rem;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.35s;

  &:hover {
    color: black;
    background-color: white;
  }
`;

export const ButtonGet = styled.button`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  /* font-size: clamp(6px, 0.9vw, 15px); 최소 10px, 기본 0.9vw, 최대 15px */
  background-color: #f68063;
  color: #fff;
  font-weight: 700;
  border: 1px solid #f68063;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #d55c4a; /* 더 어두운 색 */
  }

  &:disabled {
    background-color: #d55c4a; /* 비활성화 시 색상 */
    cursor: not-allowed; /* 커서 변경 */
    border: 1px solid #d55c4a; /* 비활성화 시 테두리 색상 */
  }
`;

export const ButtonBlack = styled.button`
  flex: 1;
  height: 40px;
  font-size: clamp(6px, 0.9vw, 15px); /* 최소 10px, 기본 0.9vw, 최대 15px */
  background-color: #fff;
  border: 1px solid rgba(33, 33, 33, 0.2); /* 초기 테두리 색 */
  transition: background-color 0.3s ease;

  cursor: pointer;

  /* hover 시 테두리 색상 변경 */
  &:hover {
    border-color: black; /* 테두리 색상 변경 */
  }
`;

export const FooterBox = styled.div`
  background-color: #ffaa77;
  display: flex;
  min-height: 10vh;
`;

export const FooterXxlBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  padding-top: 20px;
`;

export const FooterFlexBox = styled.div`
  flex: 1;
  display: flex;
  color: #fff;
  font-size: 10px;
  font-weight: 600;
`;

export const FooterFlexFlex = styled.div`
  flex: 1;
  display: flex;
  justify-content: end;
  /* flex-direction: column; */
  text-align: right;
  font-size: 10px;
  font-weight: 600;
  color: #fff;
`;

export const AppFlexBox = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.isSelected ? "#fff" : "#F68063")};
  border-radius: 8px;
  color: ${(props) => (props.isSelected ? "#0b83e6" : "#fff")};
  border: 1px solid ${(props) => (props.isSelected ? "#0b83e6" : "#F68063")};
  font-size: 14px;
  font-weight: 700;
  flex: 1;
  transition: all 0.3s ease;

  @media (max-width: 1125px) {
    font-size: 10px;
  }

  &:hover {
    color: ${(props) => (props.isSelected ? "#0b83e6" : "#F68063")};
    background-color: ${(props) => (props.isSelected ? "#fff" : "#fff")};
    border: 1px solid ${(props) => (props.isSelected ? "#0b83e6" : "#F68063")};
  }
`;
export const FileInput = styled.input`
  display: none;
`;

export const StyledSelect = styled.select`
  width: 100%;
  height: 40px;
  font-size: 16px;
  color: ${(props) =>
    props.isBackgroundImage
      ? "black"
      : "white"}; /* 배경이미지 추가 시 텍스트 색상 변경 */
  background-color: ${(props) =>
    props.isBackgroundImage
      ? "#fff"
      : props.selectedColor}; /* 배경이미지 추가 시 배경 색상은 흰색 */
  border: 1px solid
    ${(props) => (props.isBackgroundImage ? "black" : props.selectedColor)}; /* 보더 색상 */
  border-radius: 8px;
  padding: 5px 10px;
  font-weight: 700;
  cursor: pointer;

  &:focus {
    background-color: ${(props) =>
      props.isBackgroundImage ? "#f0f0f0" : props.selectedColor};
    border-color: ${(props) =>
      props.isBackgroundImage ? "black" : props.selectedColor};
    outline: none;
  }

  option {
    background-color: ${(props) => props.selectedColor};
    color: white;
    padding: 10px;
  }
`;

export const GuidText = styled.div`
  font-size: 50px;
  color: red;
  font-weight: 700;
`;

export const GuidText2 = styled.div`
  font-size: 50px;
  color: black;
  font-weight: 700;
`;

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Noto Sans', 'Noto Sans KR', sans-serif;
  }
`;

export const OptionBoxStyle = styled.div`
  padding: 10px;
  width: 100%;
`;

export const OptionTextBoxStyle = styled.div`
  font-size: 15px;
  font-weight: 700;
  color: #0b83e6;
`;

export const OptionWidthBox = styled.div`
  width: 100%;
`;

export const OptionInputTextBox = styled.input`
  width: 100%;
  border: none;
  border-bottom: 1px solid rgba(32, 89, 41, 0.3);
  outline: none;
`;

export const OptionClothesStyleBox = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
`;

export const OptionBackgroundBox = styled.div`
  width: 100%;
  margin-top: 10px;
`;

export const OptionBackgroundLabelStyle = styled.label`
  cursor: pointer;
  color: #0b83e6;
  border: 1px solid rgba(33, 33, 33, 0.2);
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 3rem;
  border-radius: 8px;
  margin-top: 10px;
`;

export const OptionBackgroundImageBox = styled.div`
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const OptionBackgroundImage = styled.img`
  width: 10rem;
  height: 10rem;
`;

export const OptionFileImageBox = styled.div`
  margin-top: 20px;
  display: flex;
`;

export const SizeBoxWidth3rem = styled.div`
  width: 3rem;
`;

export const OptionFileImageLabel = styled.label`
  cursor: pointer;
  color: #0b83e6;
  border: 1px solid rgba(33, 33, 33, 0.2);
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 3rem;
  border-radius: 8px;
`;

export const OptionFileImageViewBox = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const OptionFileMapBox = styled.div`
  position: relative;
`;

export const OptionFileViewImage = styled.img`
  width: 10rem;
  height: 10rem;
  border-radius: 8px;
`;

export const OptionImageDelButton = styled.button`
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: #0b83e6;
  color: #fff;
  border: none;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
`;

export const GuideTextBox = styled.div`
  margin-top: 3rem;
  font-size: 2rem;
`;

export const GuideImageBox = styled.div`
  margin-top: 20px;
  display: flex;
  width: 100%;
  height: 30rem;
`;

export const FlexOne = styled.div`
  flex: 1;
`;

export const GuideImageBox2 = styled.div`
  margin-top: 20px;
  display: flex;
  width: 100%;
  height: 40rem;
  padding-left: 5rem;
  padding-right: 5rem;
  @media (max-width: 850px) {
    height: 100%;
  }
  @media (max-width: 600px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
`;

export const GuideTextFinalBox = styled.div`
  border: none;
  background-color: rgba(33, 33, 33, 0.05);
  margin-top: 40px;
  margin-bottom: 30px;
`;

export const GuideTextFinal = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 30px;
  flex-direction: column;
  font-size: 18px;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const Th = styled.th`
  border: 1px solid #000;
  padding: 10px;
  text-align: center;
  background-color: #f4f4f4;
`;

export const Td = styled.td`
  border: 1px solid #000;
  padding: 10px;
  /* text-align: center; */
`;

export const GuideBoxImg = styled.img`
  width: 100%;
  height: 100%;
`;

export const PrImgBox = styled.div`
  margin-top: 10px;
  padding-left: 5rem;
  padding-right: 5rem;
  height: 40rem;
  @media (max-width: 850px) {
    height: 100%;
  }
  @media (max-width: 600px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
`;

export const FontSizeBox = styled.div`
  font-size: 20px;
  font-weight: 700;
`;

export const MustTextBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const MustBr = styled.div`
  border-radius: 16px;
  border: 3px solid black;
  width: 80%;
`;

export const MustText = styled.div`
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 3px;
`;

export const MustTwoBox = styled.div`
  margin-top: 1rem;
  margin-left: 8%;
`;

export const FontSizeBox2 = styled.div`
  margin-top: 20px;
  font-size: 20px;
  font-weight: 700;
`;

export const FontSizeBox3 = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-left: 10px;
`;
