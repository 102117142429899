import styled from "styled-components";
import { colorMap } from "./ListBox";

export const ListBoxTopBox = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-top: 2rem;
`;

export const ListBoxBorderStyled = styled.div`
  border: 1px solid rgba(33, 33, 33, 0.3);
  border-radius: 8px;
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 1rem;
  margin-left: 1rem;
`;

export const ListBoxColorBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ListBoxColorDataBox = styled.div`
  background-color: ${(props) =>
    colorMap[props.bgColor] || "#fff"}; // background_color에 맞는 색상 적용
  width: 100%;
  height: 3rem;
  border-radius: 8px;
  color: #fff;
  font-weight: 800;
  justify-content: center;
  align-items: center;
  display: flex;
`;
export const ListBoxSpanOne = styled.span`
  font-size: 18px;
  font-weight: 700;
`;

export const ListBoxSpanTwo = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: #205929;
`;

export const ListBoxContainerBox = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; /* 이미지 개수에 맞게 wrap */
`;

export const ListBoxContainerBoxTwo = styled.div`
  flex: 4;
  display: flex;
  margin-left: 2rem;
  align-content: center;
  justify-content: center;
  align-items: center;
`;

export const ListBoxImgBox = styled.div`
  height: 7rem;
  display: flex;
  flex: 3;
  justify-content: center;
  /* align-items: center; */
  width: 200px;
`;

export const ListBoxImgOne = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
export const ListBoxImgBBOX = styled.div`
  width: 180px;

  @media (max-width: 1000px) {
    margin-left: 2rem;
  }

  @media (max-width: 770px) {
    width: 130px;
    margin-left: 0;
  }
`;

export const ListBoxFlex3 = styled.div`
  /* flex: 3; */
`;

export const ListBoxTextOne = styled.div`
  font-size: 15px;
  font-weight: 700;
`;

export const ListBoxTextBox = styled.div`
  margin-top: 5px;
  font-size: 15px;
  font-weight: 700;
`;

export const ListBoxTextBoxTwo = styled.div`
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 700;
`;

export const ListBoxContainerBoxThree = styled.div`
  flex: 7;
  display: flex;
`;

export const ListBoxContainerInBoxOne = styled.div`
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const ListBoxStyledBox = styled.div`
  border-radius: 8px;
  border: 1px solid #f68063;
  background-color: #f68063;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ListBoxSizeBoxHeight03 = styled.div`
  height: 0.3rem;
`;

export const ListBoxBackground = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ListBoxBackBox = styled.div`
  height: 5rem;
  min-width: 5rem;
  width: 12rem;
`;
