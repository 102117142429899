import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Home from "./Home";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { persistor, store } from "./redux/Store";
import { PersistGate } from "redux-persist/integration/react";
import '@radix-ui/themes/styles.css';
import { Theme } from '@radix-ui/themes';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Theme appearance="light" accentColor="blue" radius="medium">
        <Home />
      </Theme>
    </PersistGate>
  </Provider>
);

reportWebVitals();
