import { useNavigate } from "react-router-dom";
import { Header } from "../header/header";
import { Footer } from "../footer/footer";
import { ListBox } from "./ListBox";
import {
  ListLoadingBox,
  ListPageAllBox,
  ListPageTitleBox,
  ListPageTopSizeBox,
  ListPageVhBox,
} from "./ListStyle";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { myPageRepo } from "./repo/ListRepo";
import { HashLoader } from "react-spinners";
import Swal from "sweetalert2";
import { resetAllAct } from "../redux/action/productAction";
import { removeToken } from "../redux/action/LoginAction";

export const ListPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toke = useSelector((state) => state.cooke);
  const [isLoading, setLoading] = useState(false);
  const [isProductData, setIsProductData] = useState([]);

  useEffect(() => {
    setLoading(true);
    // 토큰 값이 있을 때만 통신
    if (toke) {
      const fetchData = async () => {
        const response = await myPageRepo(toke);
        if (response.msg === "Success") {
          setIsProductData(response.data.data);
        } else {
          console.log("구매 목록 조회 실패:", response.data);

          Swal.fire({
            title: `로그인이 필요합니다.`,
            icon: `error`,
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/");
              dispatch(removeToken());
              dispatch(resetAllAct());
            }
          });
        }
      };

      fetchData();
      setLoading(false);
    } else {
      console.log("토큰이 없습니다.");
      // 토큰이 없다면 로그인 페이지로 이동 (예시)
      navigate("/");
    }
  }, [navigate]);
  return (
    <div>
      <Header />
      <div className="container">
        <div className="container-fluid">
          <div className="container-lg">
            <ListPageVhBox>
              <ListPageTopSizeBox></ListPageTopSizeBox>
              <ListPageTitleBox>구매목록</ListPageTitleBox>
              <ListPageAllBox>
                {isLoading === true ? (
                  <ListLoadingBox>
                    <HashLoader size={200} color="#205929" />
                  </ListLoadingBox>
                ) : (
                  isProductData.map((item, index) => (
                    <ListBox key={index} data={item} />
                  ))
                )}
              </ListPageAllBox>
            </ListPageVhBox>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
