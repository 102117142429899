import { useNavigate } from "react-router-dom";
import { IoHomeOutline } from "react-icons/io5";
import { FaArrowLeft } from "react-icons/fa";
import { SingUpPageButton } from "../style/SingUpStyle";
import { SizeBoxHeight1Rem } from "../style/AppStyle";
import mohalogo from "../image/logo.png";
import { Header } from "../header/header";
import { Footer } from "../footer/footer";
import {
  SingUpBackButtonBox,
  SingUpCheckBox,
  SingUpCheckBoxText,
  SingUpCursorBox,
  SingUpErrorMSG,
  SingUpFlexBox,
  SingUpGuidTextBox,
  SingUpGuidTextBox2,
  SingUpIconBox,
  SingUpInputBox,
  SingUpInputMarginTopBox,
  SingUpInputTitleBox,
  SingUpNavBox,
  SingUpTopBox,
  SingUpWidthBox,
} from "./SingupStyle";
import { AuthSingUp } from "./repo/SingUpRepo";
import { useState } from "react";
import { emailRegex, validatePassword } from "../util/const";
import { setCookie } from "../util/cookies";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { setToken } from "../redux/action/LoginAction";
import { ConditionsPage } from "../treaty/conditions/ConditionsPage";

export const SingUpPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isEmail, setEmail] = useState("");
  const [isUserName, setUserName] = useState("");
  const [isPassword, setPassWord] = useState("");
  const [isPasswordCheck, setPasswordCheck] = useState("");
  const [isAllCheck, setAllCheck] = useState(false);
  const [isPhoneNumber, setPhonNumber] = useState("");
  const [isCheckPass, setCheckPass] = useState(false);

  // 유효성검사 확인
  const [isEmailValue, setEmailValue] = useState(false);
  const [isEmailFail, setEmailFail] = useState(false);
  const [isUserNameValue, setUserNameValue] = useState(false);
  const [isPasswordValue, setPasswordValue] = useState(false);
  const [isPasswordCheckValue, setPasswordCheckValue] = useState(false);
  const [isAllCheckValue, setAllCheckValue] = useState(false);
  const [isPhoneNumberValue, setPhoneNumberValue] = useState(false);
  const [isAllPass, setAllPass] = useState(true);
  const [isPasswordSameCheck, setPasswordSameCheck] = useState(false);
  const [isPasswordFail, setPasswordFail] = useState(false);

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handlePhoneNumber = (e) => {
    setPhonNumber(e.target.value);
  };

  const handleUserName = (e) => {
    setUserName(e.target.value);
  };

  const handlePassword = (e) => {
    setPassWord(e.target.value);
  };

  const handlePasswordCheck = (e) => {
    setPasswordCheck(e.target.value);
  };

  const handleAllCheck = (e) => {
    setAllCheck(!isAllCheck); // 체크 상태를 반전시킴
  };

  const handleCertifiedCheck = async (
    name,
    email,
    password,
    passwordCheck,
    allCheckbox,
    isPhoneNumber
  ) => {
    setCheckPass(true);
    let pass = true;

    // 이름이 빈칸일경우
    if (name.trim() === "") {
      setUserNameValue(true);
      pass = false;
    } else {
      setUserNameValue(false);
    }

    // 휴대폰 번호가 빈칸일 경우
    if (isPhoneNumber.trim() === "") {
      setPhoneNumberValue(true);
      pass = false;
    } else {
      setPhoneNumberValue(false);
    }

    // 이메일이 빈칸일 경우
    if (email.trim() === "") {
      setEmailValue(true);
      pass = false;
    } else {
      setEmailValue(false);
    }

    //이메일 형식이 틀릴떄
    if (!emailRegex.test(email)) {
      // 정규식에 매칭되지 않을 때
      setEmailValue(false);
      setEmailFail(true);
      pass = false;
    } else {
      // 정규식에 매칭될 때
      setEmailFail(false);
      setEmailValue(false); // 이메일 상태를 성공으로 변경
    }
    // 비밀번호가 빈칸일 경우
    if (password.trim() === "") {
      setPasswordValue(true);
      setPasswordCheckValue(false);
      setPasswordSameCheck(false);
      setPasswordFail(false);

      pass = false;
    } else if (!validatePassword(password)) {
      setPasswordValue(false);
      setPasswordCheckValue(false);
      setPasswordSameCheck(false);
      setPasswordFail(true);

      pass = false;
    } else if (password !== passwordCheck) {
      setPasswordValue(false);
      setPasswordCheckValue(false);
      setPasswordSameCheck(true);
      setPasswordFail(false);

      pass = false;
    } else {
      setPasswordValue(false);
      setPasswordCheckValue(false);
      setPasswordSameCheck(false);
      setPasswordFail(false);
    }

    // 비밀번호 확인이 빈칸일경우
    if (passwordCheck.trim() === "") {
      setPasswordCheckValue(true);
      pass = false;
    } else {
      setPasswordCheckValue(false);
    }
    // 채크박스 체크를 안했을 경우
    if (allCheckbox === false) {
      setAllCheckValue(false);

      pass = false;
    } else {
      setAllCheckValue(true);
    }

    // 검증로직이 통화하지 않았는지 확인
    if (!pass) {
      setCheckPass(false);
      return;
    }

    const resp = await AuthSingUp(email, password, name, isPhoneNumber);
    if (resp.msg === "Success") {
      setCookie("accessToken", resp.data.data.accessToken, {
        path: "/",
        maxAge: 3600,
      });

      dispatch(setToken(resp.data.data.accessToken));

      Swal.fire({
        title: "회원가입이 완료되었습니다.",
        icon: `success`,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/");
        }
      });

      return;
    } else {
      if (resp.data.response.data.message === "이메일중복") {
        Swal.fire({
          title: `중복된 이메일입니다.`,
          icon: `error`,
        });
      }

      if (resp.data.response.data.message === "핸드폰번호중복") {
        Swal.fire({
          title: `중복된 휴대폰번호입니다.`,
          icon: `error`,
        });
      }

      return;
    }
  };
  return (
    <div>
      <Header />
      <div className="container">
        <div className="container-fluid">
          <div className="container-sm">
            <div></div>

            <SingUpTopBox>
              <SingUpWidthBox>
                <SingUpIconBox>
                  <SingUpNavBox onClick={() => navigate("/LoginPage")}>
                    <FaArrowLeft size={20} />
                    <SingUpBackButtonBox>뒤로가기</SingUpBackButtonBox>
                  </SingUpNavBox>
                  <SingUpCursorBox onClick={() => navigate("/")}>
                    <IoHomeOutline size={20} />
                  </SingUpCursorBox>
                </SingUpIconBox>
                <SingUpGuidTextBox>
                  <SingUpGuidTextBox2>회원 정보 입력</SingUpGuidTextBox2>
                  <div>아이디로 사용된 이메일 주소는 변경이 불가능합니다.</div>
                  <div>
                    이메일 주소를 정확히 입력했는지 한 번 더 확인해주세요!
                  </div>
                </SingUpGuidTextBox>
                <SingUpInputTitleBox>이름</SingUpInputTitleBox>
                <SingUpInputBox
                  type="text"
                  placeholder="User Name"
                  value={isUserName}
                  onChange={handleUserName}
                ></SingUpInputBox>
                {isUserNameValue === true && (
                  <SingUpErrorMSG>이름을 입력해주세요.</SingUpErrorMSG>
                )}
                <SizeBoxHeight1Rem />
                <SingUpInputTitleBox>휴대폰번호</SingUpInputTitleBox>
                <SingUpInputBox
                  type="text"
                  placeholder="User Phone Number"
                  value={isPhoneNumber}
                  onChange={handlePhoneNumber}
                ></SingUpInputBox>
                {isPhoneNumberValue === true && (
                  <SingUpErrorMSG>휴대폰 번호를 입력해주세요</SingUpErrorMSG>
                )}
                <SizeBoxHeight1Rem />
                <SingUpInputTitleBox>이메일</SingUpInputTitleBox>
                <SingUpInputBox
                  type="text"
                  placeholder="e-mail"
                  value={isEmail}
                  onChange={handleEmail}
                ></SingUpInputBox>
                {isEmailValue === true && (
                  <SingUpErrorMSG>이메일을 입력해주세요</SingUpErrorMSG>
                )}
                {isEmailFail === true && (
                  <SingUpErrorMSG>
                    유효하지 않은 이메일 주소입니다.
                  </SingUpErrorMSG>
                )}
                <SizeBoxHeight1Rem />
                <SingUpInputTitleBox>비밀번호</SingUpInputTitleBox>
                <SingUpInputBox
                  type="password"
                  placeholder="Password"
                  value={isPassword}
                  onChange={handlePassword}
                ></SingUpInputBox>
                {isPasswordValue === true && (
                  <SingUpErrorMSG>비밀번호를 입력해주세요</SingUpErrorMSG>
                )}
                {isPasswordSameCheck === true && (
                  <SingUpErrorMSG>비밀번호가 일치하지 않습니다.</SingUpErrorMSG>
                )}
                {isPasswordFail === true && (
                  <SingUpErrorMSG>
                    8~16 자리의 비밀번호를 입력해주세요.
                  </SingUpErrorMSG>
                )}
                <SizeBoxHeight1Rem />
                <SingUpInputTitleBox>비밀번호 확인</SingUpInputTitleBox>
                <SingUpInputBox
                  type="password"
                  placeholder="Password Check"
                  value={isPasswordCheck}
                  onChange={handlePasswordCheck}
                ></SingUpInputBox>
                {isPasswordCheckValue === true && (
                  <SingUpErrorMSG>비밀번호 확인을 입력해주세요.</SingUpErrorMSG>
                )}
                {isPasswordSameCheck === true && (
                  <SingUpErrorMSG>비밀번호가 일치하지 않습니다.</SingUpErrorMSG>
                )}
                <SizeBoxHeight1Rem />
                <SingUpFlexBox>
                  <SingUpCheckBox
                    type="checkbox"
                    value={isAllCheck}
                    onChange={handleAllCheck}
                  ></SingUpCheckBox>
                  <SingUpCheckBoxText>약관동의</SingUpCheckBoxText>
                </SingUpFlexBox>
                {/* {isAllCheckValue === false && (
                  <SingUpErrorMSG>약관동의가 필요합니다</SingUpErrorMSG>
                )} */}

                <SingUpInputMarginTopBox>
                  <ConditionsPage />
                </SingUpInputMarginTopBox>
                <SingUpPageButton
                  onClick={() =>
                    handleCertifiedCheck(
                      isUserName,
                      isEmail,
                      isPassword,
                      isPasswordCheck,
                      isAllCheck,
                      isPhoneNumber
                    )
                  }
                >
                  가입하기
                </SingUpPageButton>
              </SingUpWidthBox>
            </SingUpTopBox>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
