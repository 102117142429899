import { Footer } from "../footer/footer";
import { Header } from "../header/header";
import { SizeTreatyBox } from "../style/AppStyle";
import { InformationPage } from "./information/InformationPage";

export const ToInformationPage = () => {
  return (
    <div>
      <Header></Header>
      <div className="container">
        <div className="container-fluid">
          <div className="container-sm">
            <SizeTreatyBox></SizeTreatyBox>
            <InformationPage />
            <SizeTreatyBox></SizeTreatyBox>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};
