import api from "../../util/api";

export const myPageRepo = async (token) => {
  try {
    const resp = await api.post("/mypage/", null, {
      headers: {
        Authorization: `Bearer ${token}`, // 토큰을 Authorization 헤더에 포함시킴
      },
    });

    return {
      msg: "Success",
      data: resp,
    };
  } catch (error) {
    console.log("구매목록 가져오기 실패", error);
    return {
      msg: "Fail",
      data: error,
    };
  }
};
