// reducer.js

import { REMOVE_TOKEN, SET_TOKEN } from "../action/LoginAction";

const initialState = {
  token: null, // 초기 상태
};

const tokenReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload, // 토큰 저장
      };
    case REMOVE_TOKEN:
      return {
        token: null, // 토큰 제거
      };
    default:
      return state;
  }
};

export default tokenReducer;
