import api from "../../util/api";
import { formatKoreanPhoneNumber } from "../../util/const";

export const AuthSingUp = async (email, password, name, phone) => {
  try {
    if (phone.startsWith("+82")) {
      const formattedPhone = formatKoreanPhoneNumber(
        phone.replace("+82", "").trim()
      );
      phone = "+82 " + formattedPhone;
    }

    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    formData.append("phone", phone);
    formData.append("name", name);

    const resp = await api.post("/auth/signup", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return {
      data: resp,
      msg: "Success",
    };
  } catch (error) {
    console.log("회원가입 실패", error);
    return {
      data: error,
      msg: "Fail",
    };
  }
};
